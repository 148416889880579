import React, { useContext, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails,
       Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography,  
      FormControl, Select, MenuItem, InputLabel, Box
    } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CompanyContext } from "../CompanyContext";

const InvsItem = (props) =>{
  
 // const {classes, institutionAssetClassYearList: years} = useContext(CompanyContext);
  const {classes} = useContext(CompanyContext);
  const item = props.item
  const years = []

  item.groupings.forEach(assetAlloc => {
    const { variables } = assetAlloc
    variables.forEach(variable => {
      const { yearList } = variable
      yearList.forEach(year => {
        if (!years.includes(year)) {
          years.push(year)
        }
      });
    });
  });

  years.sort((a, b) => a < b ? 1 : a > b ? -1 : 0)

  // console.log(item, years);
  //{name: 'Private Debt', seq: 5, groupings: Array(2)}
  //const [yearOneData, setOneData] = useState([]);
  //const [yearTwoData, setTwoData] = useState([]);
  const [yearOne, setOneValue] = useState(years[0]||'');
  const [yearTwo, setTwoValue] = useState(years[1]||'');
  const [yearThree, setThreeValue] = useState(years[2]||'');

  const handleYearOneChange = (event)=>{
    setOneValue(event.target.value);
    //console.log(event.target.value, item.variables.filter(o=>(parseInt(o.getYear)===event.target.value)));
    //setOneData(item.variables.filter(o=>(parseInt(o.getYear)===event.target.value)));
  }

  const handleYearTwoChange = (event)=>{
    setTwoValue(event.target.value);
    //setTwoData(item.variables.filter(o=>(parseInt(o.getYear)===event.target.value)));
  }

  const handleYearThreeChange = (event) => {
    setThreeValue(event.target.value);
    //setTwoData(item.variables.filter(o=>(parseInt(o.getYear)===event.target.value)));
  }

  const renderTableCell = (name, data, i)=>{
    
    let dataYears = data.map(o=>parseInt(o.getYear));
    let emptystr = '';
    //console.log(name, data, dataYears, dataYears.indexOf(yearOne));
    //console.log(name, data);
    return(
      <TableRow key={`row${i}`}>
        <TableCell scope="row">{name}</TableCell>
          {years.length > 2 && (
            <TableCell scope="row" align="right" >
              {data.map((row, j) => {
                emptystr = (j===0)?'':emptystr;
                if(parseInt(row.getYear)===yearThree)
                  return <span key={`year3${row.getYear}${j}`}>{row.getValueStr}</span>
                else if(dataYears.indexOf(yearThree)===-1 && emptystr===''){
                  emptystr += '--';
                  return emptystr
                }else
                  return '';

              })}
            </TableCell>
          )}
          {years.length > 1 && (
            <TableCell scope="row" align="right" >
              {data.map((row, j) => {
                emptystr = (j===0)?'':emptystr;
                if(parseInt(row.getYear)===yearTwo)
                  return <span key={`year2${row.getYear}${j}`}>{row.getValueStr}</span>
                else if(dataYears.indexOf(yearTwo)===-1 && emptystr===''){
                  emptystr += '--';
                  return emptystr
                }else
                  return '';

              })}
            </TableCell>
          )}
          <TableCell scope="row" align="right">
            {data.map((row, j) => {
              emptystr = (j===0)?'':emptystr;
              if(parseInt(row.getYear)===yearOne)
                return <span key={`year1${row.getYear}${j}`}>{row.getValueStr}</span>
              else if(dataYears.indexOf(yearOne)===-1 && emptystr===''){
                emptystr += '--';
                return emptystr
              }else
                return '';
            })}
          </TableCell>
        </TableRow>
      )
  }

  return (
    <div key={`accord-${item.name}`} className={classes.accord}>
      <Accordion defaultExpanded={!props.isShow} className={classes.accordWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography variant="subtitle2">{item.name}</Typography>
        </AccordionSummary>
        {!props.isShow && <AccordionDetails className={classes.accordBody}>
          <div className={classes.accordTable}>
            <TableContainer>
              <Table className={classes.accordTableBody} size="small" >
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell scope="row" ></TableCell>
                    {years.length > 2 &&  
                      <TableCell scope="row" align="right" className={classes.yearHeader}>
                        <FormControl className={classes.select}>
                          <Select
                            value={yearThree}
                            onChange={handleYearThreeChange}
                          >
                            {years.map((year, i) => <MenuItem key={`year3` + year + i} value={year}>{year}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </TableCell>
                    }
                    {years.length > 1 &&
                      <TableCell scope="row" align="right" className={classes.yearHeader}>
                        <FormControl className={classes.select}>
                          <Select
                            value={yearTwo}
                            onChange={handleYearTwoChange}
                          >
                            {years.map((year, i) => <MenuItem key={`year2` + year + i} value={year}>{year}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </TableCell>
                    }

                    <TableCell scope="row" align="right" className={classes.yearHeader}>
                      <FormControl className={classes.select}>
                        <Select
                          value={yearOne}
                          onChange={handleYearOneChange}
                        >
                          {years.map((year, i) => <MenuItem key={`year1`+year+i} value={year}>{year}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.groupings.map((group,g)=>(
                    <>
                      <TableRow key={`group-${g}`}>
                        <TableCell colSpan={4}>
                          <Box mt={2}>
                            <Typography variant="subtitle2">{group.name}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {group.variables.map((row, i)=>(
                        renderTableCell(row.name, row.variables, i)
                      ))}
                    </>
                  ))}
                
                  {/*<Table className={classes.accordTableBody} size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">{data.getYear}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.variables.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.value}{row.typeName}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>*/}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </AccordionDetails>}
      </Accordion>
    </div>
  );
}

export default InvsItem;
