import React, { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, AppBar, Toolbar, Container, Grid, makeStyles, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchBar from './SearchBar';
import DrawerMenu from './DrawerMenu';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../components/LoginButton';
import LogoutButton from '../components/LogoutButton';
import { deepOrange } from '@material-ui/core/colors';
import logo from '../assets/images/AI-Inverted.png';
import Aoilogo from '../assets/images/Red-White-150x50.png';
import AoiIcon from '../assets/images/logo192.png';
import { CompanyContext } from "../components/CompanyContext";
//import Logo from 'src/components/Logo';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarItem: {
    '& .MuiGrid-item':{
      padding:'10px 0 0'
    }
  },
  item:{
    display:'none',
    [theme.breakpoints.up('md')]: {
      display:'block'
    }
  },
  logo:{
    '& img':{maxWidth:'180px',
      marginTop:theme.spacing(2)
    },
    marginLeft:theme.spacing(1),
    marginRight:theme.spacing(1)
  },
  container:{
    padding:0
  },
  mobileLogo:{
    '& img':{maxWidth:'40px'},
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display:'block',
      marginRight:'20px'
    }

  },
  toolbar: {
    height: 64,
    backgroundColor:theme.palette.background.black
  },
  search:{
    flexGrow: 2,
    [theme.breakpoints.down('sm')]: {
      display:'block',
      paddingTop:'0!important'
    }
  },
  profile:{
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: '5px',
    display:'block',
    [theme.breakpoints.up('md')]: {
      display:'none'
    }
  },
  hide: {
    display: 'none',
  },
  profileLink:{
    textDecoration:'none !important'
  }
  

}));

const TopBar = ({ className, drawerHandler, searchBar, path,...rest }) => {
  let history = useHistory();
  const classes = useStyles();
  const { isAuthenticated, user, isLoading, loginWithRedirect, logout, error, getAccessTokenSilently} = useAuth0();
  const [open, setOpen] = React.useState(false);
  

  const [request, setRequest] = useState(undefined);
  const {setRedirectFunc, searchKeywords} = useContext(CompanyContext);
  /*const [token, setToken] = React.useState(null);
  useEffect(() => {
    async function getToken(){
      const token = await getAccessTokenSilently();
      console.log('token',token);
      setToken(token);
    }
    getToken();
  }, []);*/

  const handleDrawerOpen = () => {
    setOpen(true);
    drawerHandler(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    drawerHandler(false);
  };  

  const showProfile = (name) =>{
    return <Avatar className={classes.orange}>{name.substr(0,1).toUpperCase()}</Avatar>
  }
  if (error) {
    console.log(error);
  }
  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.appBar, classes.appBarItem, {
            [classes.appBarShift]: open,
          })}
        elevation={0}
        position="fixed"
        {...rest}
      >
        <Toolbar className={classes.toolbar}>
          <Container maxWidth={'lg'} className={classes.container}>
            <Grid container >
              {/*<Grid item className={clsx(
                    classes.mobileLogo, {[classes.hide]: open
                  })}>
                <Link href="/">
                  <img className={classes.icon} alt="complex" src={AoiIcon} />
                </Link>
              </Grid>*/}
              <Grid item className={classes.menuButton}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx({
                    [classes.hide]: open
                  })}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item className={classes.item}>
                <Link to={"/"}>
                  <img className={classes.img} alt="complex" src={Aoilogo} />
                </Link>
              </Grid>
              <Grid item className={clsx(classes.search, classes.item, {[classes.hide]: open})}>
                {searchBar && <SearchBar path={path} searchKeywords={searchKeywords} submitSearch={setRedirectFunc} />}
              </Grid>
              <Grid item  className={clsx(classes.logo, classes.item)}>
                <img className={classes.img} alt="Asian Investor" src={logo} />
              </Grid>
              <Grid item  className={classes.item}>
                {isAuthenticated && !isLoading ?
                  <div className={classes.profile}>
                    <Link className={classes.profileLink} to={'/account'}>{showProfile(user.nickname)}</Link>
                    <LogoutButton/>
                  </div>
                  : <div className={classes.profile}>
                      <LoginButton/>
                    </div>
                }
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={open} handleDrawerClose={handleDrawerClose}/>
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;