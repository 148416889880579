import React, { useState, useEffect, useReducer } from 'react';
import { Typography,  makeStyles, Grid} from '@material-ui/core';
import { FilterCheckBox } from './';

import clsx from 'clsx';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root:{},
  item: {
    borderColor:'#ececec',
    borderWidth:'1px',
    borderStyle:"solid",
    margin:'-1px',
    padding:'5px 10px'
  },

}));

const AssetList = (props) => {
  const classes = useStyles();
  const [selectedAssetVn, setSelectedAssetVn] = useState({});
  let {data, selectedItems} = props;

  //console.log(data, selectedItems);
  const updateAssetVn = (key, selected)=>{
    let selectedVn = {...selectedAssetVn}, selectedList = [];
    selectedVn[key] = selected;
    setSelectedAssetVn(selectedVn);
    Object.keys(selectedVn).forEach(key => {
      selectedVn[key].forEach(o=>{
        selectedList.push(o)
      })
    });
    return selectedList;
/*     let asVnList = selected.filter(o=>{
      return o!==undefined;
    });
    return asVnList; */
  }
  const handleAssetVnChange = (event,key)=>{
    //console.log(event.value, key);
    if(event.value && event.value!==selectedAssetVn){
      let asVnList = updateAssetVn(key,event.value)
      //console.log(asVnList);
      props.onSelectItems(asVnList);
    }
    
  }
  //console.log(selectedAssetVn);

  useEffect(()=>{
    let tempSelectedAssetVn = {};
    //console.log(selectedItems)
    if(selectedItems?.length>0){
      data.forEach((d,i)=>{
        //console.log(d.children);
/*         let found = d.children.find(c=>{
          console.log(c.key,(selectedItems.indexOf(c.key)>-1));
          return (selectedItems.indexOf(c.key)>-1)
        }) */
        let founds = [];
        d.children.forEach(c=>{
          if(selectedItems.indexOf(c.key)>-1)
            founds.push(c.key)
        });
        tempSelectedAssetVn[i]=founds;
          
      })
      setSelectedAssetVn(tempSelectedAssetVn);
    }
  },[]);
  //console.log(data, selectedAssetVn);
  return (
    <div className={classes.root}>
      { data?.map((o,i)=>{
        if(o?.children?.length>0){
          return <Grid className={classes.item} key={`${o.key}-${i}`} container alignItems="center" alignContent="space-between" >
            <Grid item xs={9}>
              <Typography style={{display: 'inline-block'}} variant="subtitle2">{o.parent} :</Typography>
              <Typography style={{display: 'inline-block'}} variant="body2">{o.text}</Typography>
            </Grid>
            <Grid item xs={3}>
              <FilterCheckBox 
                label={false}
                convertItem={true}
                multiple
                width={400}
                onSelect={(e)=>handleAssetVnChange(e,i)} 
                selectedItem={selectedAssetVn[i]||[]}
                items={o.children} 
                id={'assetClassesVnFilter'}
                filterName={''}/>
            </Grid>
          </Grid>
        }
        
      }
    )}
    </div>
  );

  
};

export default AssetList;
