import React from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Drawer, Divider,
        List, useTheme, IconButton, makeStyles, Grid} from '@material-ui/core';
import { 
  ChevronLeft as IconChevronLeft, 
  ChevronRight as IconChevronRight, 
  Search as IconSearch, 
  Home as IconHome, 
  ExitToApp as IconExitToApp
} from '@material-ui/icons/';
import Aoilogo from '../assets/images/Red-Black-150x50.png';
import { useAuth0 } from "@auth0/auth0-react";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }
}));

const DrawerMenu = ({handleDrawerClose, open}) => {

  let history = useHistory();
  const { isAuthenticated, loginWithRedirect, logout} = useAuth0();
  const theme = useTheme();
  const classes = useStyles();
  
  const MenuData = [
    {name:'Home', icon:<IconHome/>, url:'/'}, 
    {name:'Search', icon:<IconSearch/>, url:'/search'}, 
    {name:(isAuthenticated)?'Logout':'Login', icon:<IconExitToApp/>, url:'/'}
  ];

  const clickHandler = (item)=>{
    if(item.name==='Logout')
      logout({returnTo: process.env.REACT_APP_URL});
    else if(item.name==='Login')
      loginWithRedirect();
    else
      history.push(item.url);
  }

  return (
    <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
      <div className={classes.drawerHeader}>
        <Grid container>
          <Grid item>
            <img src={Aoilogo} />
          </Grid>
          <Grid item>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <IconChevronLeft /> : <IconChevronRight />}
            </IconButton>
          </Grid>
        </Grid>
        
      </div>
      <Divider />
      <List>
        {MenuData.map( item => (
          <ListItem button key={item.name} onClick={()=>clickHandler(item)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default DrawerMenu;