import React, { useState, useEffect, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Container, makeStyles, Grid} from '@material-ui/core';
import { useParams, useLocation, Link } from "react-router-dom";
import { DataGrid, ColDef } from '@material-ui/data-grid';
import { Filter, useAsyncHook, FilterCheckBox } from './';

import clsx from 'clsx';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  

}));

const AssetFilters = (props) => {
  const classes = useStyles();
  let { selectedItems, selectedAssets, initAssetGroups } = props;
  let {flAssetClasses, flAssetClassesStr, flAssetClassesList, flAssetClassesId, flAssetClassesVariableGroupingStr} = props.data;
  

  const [selectedAssetNames , setSelectedAssetNames] = useState(selectedItems||[]);
  const [selectedAssetVn, setAssetVn] = useState([]);
  const [selectedAssetGroups, setSelectedAssetGroup] = useState(initAssetGroups||[]);
  const [selectedAssetClassList, setSelectedAssetClassList] = useState([]);

  const [assetClassesStr, setAssetsStr] = useState([]);
  const [assetGroupsStr, setAssetsGroupsStr] = useState();
  const [assetClassesData, setAssetClassesData] = useState([]);

  useEffect(()=>{
    let tempFlAssetClassesStr, tempFlAssetClasses; 
    if(flAssetClassesStr){
       tempFlAssetClassesStr = flAssetClassesStr.map(o=>{
        return {key: o}
      });
      setAssetsStr(tempFlAssetClassesStr);
    }

    if(flAssetClasses){
      tempFlAssetClasses = flAssetClasses.map(o=>{
        return JSON.parse(o.key)
      });
      setAssetClassesData(tempFlAssetClasses);
    }
    
  },[flAssetClassesStr, flAssetClassesList, flAssetClassesId, flAssetClassesVariableGroupingStr]); 

  const updateAsset = (selectedNames)=>{
    //console.log(selectedNames, flAssetClassesList)
    let tempGroups = [];
    
    let data = flAssetClassesList.filter(o=>{
      return (selectedNames.indexOf(o.name)>-1);
    });
    //console.log(data);
    setSelectedAssetNames(selectedNames);
    setSelectedAssetClassList(data);
    data.forEach(o=>{
      o.variables.forEach(v=>{
        v.types.forEach(t=>{
          if(tempGroups.indexOf(t.flAssetClassVariableGroupingName)===-1)
            tempGroups.push(t.flAssetClassVariableGroupingName)
        });
      });
    })
    setAssetsGroupsStr(tempGroups.map(o=>{return {key:o,text:o}}))
  }

  useEffect(()=>{
    //console.log(selectedAssets,assetClassesData);
    let data, AssetClassName;
    if(assetClassesData.length>0){
      data = assetClassesData.filter(o=>{
        return (selectedAssets.indexOf(o.Key)>-1);
      });
      
      AssetClassName = _.uniqBy(data, function (e) {
        return e.AssetClassName;
      });

      AssetClassName = AssetClassName.map(o=>{
        return o.AssetClassName;
      })
      //console.log(data, AssetClassName);
      updateAsset(AssetClassName);
    }
    
  },[assetClassesData]); 

  useEffect(()=>{
    //console.log(selectedItems, selectedAssetGroups, selectedAssetClassList);
    //if(selectedAssetGroups?.length>0 && selectedAssetClassList?.length>0){
    if(selectedAssetClassList?.length>0){
      let asList = [], children, curr;
      //console.log(selectedAssetClassList);
      selectedAssetClassList.forEach(o=>{
        o.variables.forEach(v=>{
  
          children = [];
          curr = (curr!==v.flAssetClassVariableName)?v.flAssetClassVariableName:undefined;
          v.types.forEach(t=>{
            if(selectedAssetGroups.indexOf(t.flAssetClassVariableGroupingName)!==-1)
              children.push({parent:curr, key:t.key, text:`${t.flAssetClassVariableTypeName}`});
          });
          asList.push({parent:o.name, key:curr, text:`${v.flAssetClassVariableName}`, children:children});
        });
        //console.log(asList);
        props.onAssetListingChange(asList);
      })
    }
  },[selectedAssetGroups, selectedAssetClassList])


  const handleAssetNameChange = (event)=>{
    if(event.value && event.value!==selectedAssetNames){
      //console.log(event.value)
      updateAsset(event.value);
      setSelectedAssetGroup([])
      props.onSelectedAssetNames(event.value);
    }
  }
  const handleAssetGroupChange = (event)=>{
    if(event.value && event.value!==selectedAssetGroups){
      console.log(event.value);
      //if(!Array.isArray(selectedAssetGroups))
        setSelectedAssetGroup([event.value])
      //else
        //setSelectedAssetGroup(event.value)
      props.onSelectedAssetsGrp(event.value);
    }
      
  }
  //console.log(assetClassesStr, selectedAssets , selectedAssetGroups,selectedAssetNames);

  return (
    <>
      <Grid container spacing={2} >
        <Grid item xs={12} md={6}>
          {assetClassesStr && 
            <FilterCheckBox 
              label={true}
              multiple
              onSelect={handleAssetNameChange} 
              selectedItem={selectedAssetNames}
              items={assetClassesStr} 
              id={'assetClassesFilter'}
              filterName={'Asset Classes'}/>
          }
        </Grid>
        <Grid item xs={12} md={6}>
          {assetGroupsStr && selectedAssetNames?.length > 0 &&
            <FilterCheckBox 
              label={true}
              onSelect={handleAssetGroupChange} 
              selectedItem={selectedAssetGroups}
              items={assetGroupsStr} 
              id={'assetClassGroupFilter'}
              filterName={'Asset Grouping'}/>
          }
        </Grid>
      </Grid>
    </>
  );

  
};
/* <FilterCheckBox 
    multiple
    hideSelected={true}
    width={400}
    onSelect={handleAssetVnChange} 
    selectedItem={selectedAssetVn}
    items={assetClassesVn} 
    id={'assetClassesVnFilter'}
    filterName={''}/> */
export default AssetFilters;
