import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import theme from './theme';
import GlobalStyles from './theme/GlobalStyles';
import Layout from './layouts/Layout';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { HomePage, LoginPage, ResultPage, SearchPage, InsightPage, CompanyPage, 
        AccountPage, NotFoundPage, GraphicsPage, DynamicPage } from './views';
import { Loading, useGoogleAnalytics } from './components';

const history = createBrowserHistory();

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

const WithoutProtectedRoute = ({ component, ...args }) => (
  <Route component={component} {...args} />
);

const App = (props) =>{
  const { isLoading, user } = useAuth0();
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){ console.log(user);}
  useGoogleAnalytics(history);
  //return <div/>;
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{'AsianInvestor - Asset Owner Insight'}</title>
        <meta name="description" content={'Asset Owner Insights (AOI) is AsianInvestor’s new and unique portal designed to enhance transparency within the region’s institutional asset management landscape.'}/>
      </Helmet>
      <GlobalStyles />
      {isLoading?
        <Loading/>
        :
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/login" exact component={LoginPage} />
            <ProtectedRoute path="/search" exact component={SearchPage} />
            <ProtectedRoute path="/results/:keywords?" component={ResultPage}/>
            <ProtectedRoute path="/insight/:keywords?" component={InsightPage}/>
           
            <ProtectedRoute path="/account" component={AccountPage}/>
            <ProtectedRoute path="/graphics" component={GraphicsPage}/>
            <Route path="/terms" component={DynamicPage}/>
            <WithoutProtectedRoute path="/institution/:id?" component={CompanyPage}/>
           
            
            <Route path="/privacy" component={DynamicPage}/>
            <Route path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      }
      
    </ThemeProvider>
  );

}
/*
const App = (props)=>{
  return (
    <BrowserRouter>
      <Routes {...props}/>
    </BrowserRouter>
  );
  
}*/

export default App;

//https://stackoverflow.com/questions/59653687/react-authentication-auth0-what-is-the-right-way
//https://stackoverflow.com/questions/58033342/react-context-and-hookrouter-doesnt-redirect-on-component-mount
