import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableRow, Grid, Typography } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";

const Background = (props) =>{
  const {classes, name, websiteUrl, institutionType, countryName} = useContext(CompanyContext);
  return (
    <Grid id={props.id} container spacing={2} className={classes.section}>
      <Grid item xs={12} className={classes.subTitle}>
        <Typography variant="subtitle1" gutterBottom>
          {'Company background'}
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TableContainer>
              <Table className={classes.bgTable} size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow >
                    <TableCell align="left">{"Name"}</TableCell>
                    <TableCell align="right">{name}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell align="left">{"Institution Type"}</TableCell>
                    <TableCell align="right">{institutionType}</TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer>
              <Table className={classes.bgTable} size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow >
                    <TableCell align="left">{"Country"}</TableCell>
                    <TableCell align="right">{countryName}</TableCell>
                  </TableRow>
                  { websiteUrl &&
                    <TableRow >
                      <TableCell align="left">{"Website"}</TableCell>
                      <TableCell align="right">
                        <Link to={websiteUrl}>{websiteUrl}</Link>
                      </TableCell>
                    </TableRow>
                  }
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Background;