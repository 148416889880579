import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white, 
      black: '#000'
    },
    primary: {
      main: '#ED1C24',
      dark: '#a51319',
      light: '#f0494f'
    },
    secondary: {
      main: '#4D91D1',
      dark: '#356592',
      light: '#70a7da'
    },
    text: {
      primary: '#000',
      secondary: "#fff"
    }
  },
  shadows,
  typography
});

export default theme;