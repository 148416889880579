import ReactGA from "react-ga"
 
const TRACKING_ID = process.env.REACT_APP_GA
 
function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  //console.log(isDev);
  ReactGA.initialize(TRACKING_ID, { debug: isDev })
}
 
function sendEvent(payload) {
  ReactGA.event(payload)
}
 
function setPage(path) {
  ReactGA.set({ page: path })
}
function sendPageview(path){
  ReactGA.pageview(path);
}
 
export default {
  init,
  sendEvent,
  setPage,
  sendPageview,
}