import React, { useState, useEffect } from 'react';
import { Typography, Container, makeStyles, Grid, Box } from '@material-ui/core';
import { useLocation,  Link as RLink } from 'react-router-dom';
import { CompanyProvider } from '../components/CompanyContext';
import Layout from '../layouts/Layout';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p.MuiTypography-gutterBottom':{
      marginBottom:theme.spacing(3),
      
    },
    '& div.MuiTypography-gutterBottom':{
      marginBottom:theme.spacing(3),
      '& p':{
        marginBottom:theme.spacing(1),
      }
    },
    '& ul':{
      marginLeft:theme.spacing(3),
    },
    '& ol':{
      marginLeft:theme.spacing(3),
    }

    
  },
  container:{
    minHeight:'500px'
  },
  textContainer:{
    padding: theme.spacing(3, 3)
  },
  title:{
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
  },
  

}));

const renderPage = (path, classes) => {
  switch(path) {
    case '/terms':
      return(
        <Grid container alignItems="flex-start" direction="column" justify="center" className={classes.textContainer}>
          <Box>
              <Typography variant="h2" className={classes.title}>Terms and Conditions</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  The following terms and conditions (&quot;the Terms and Conditions&quot;) apply to all information accessed from this Site (&quot;the Site&quot;). Please read them carefully. By accessing the Site, you are agreeing to the terms that appear below, whether or not you register as a user. You hereby confirm that all of the information that you may receive from the Site is for information purposes only and should be independently verified before relying on the same.</p>
                <p>
                  These terms and conditions were last updated on 6<sup>th</sup>&nbsp;Apr 2020.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>INTRODUCTION</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  This Site is owned and operated by Haymarket Media Limited (&quot;Haymarket&quot;), a Hong Kong registered company.</p>
                <p>
                  Users will be able to access some of the information and data on the Site without going through the registration process, though certain areas are only accessible if users register. Some of the material is offered free, however, if additional material is considered Premium Content, users will be asked to pay by giving their credit card details. Haymarket reserves the right to suspend or terminate a user&#39;s access to the Site at any time and may exercise this right with or without notice.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>PROPRIETARY RIGHTS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  The Site and all information, material and reports on this Site are proprietary to and the copyright belongs to Haymarket or its third party partners. Copying, reproducing, republishing, redistributing or in any way modifying the information without the prior written permission of Haymarket is prohibited. The information contained on the Site should be used for personal non-commercial use only. All trade marks and logos appearing on the Site are Haymarket&#39;s or its third party partners&#39;. They cannot be used without the written permission of Haymarket.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>THIRD PARTY INFORMATION</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  The information available on the Site may contain information provided by third parties or from other sources or references. Haymarket will endeavour to ensure that such information is up to date and accurate at the time it is posted on the Site, however Haymarket does not verify such information and therefore caution should be exercised in relation to the reliability and accuracy of the information.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>REGISTRATION AND EMAIL NEWSLETTERS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  When registering with the Site and its email newsletters, users must provide accurate and complete registration information, in particular their email address. Only work email is accepted for registration, and it must correspond to a named email account and not a generic or shared email account. The use of the content in these newsletters is subject to these Terms and Conditions.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>PAID PRINT MAGAZINE &amp; ONLINE SUBSCRIPTION POLICY&nbsp;</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Subscribers must provide accurate and complete information to fulfil subscription orders. It is the subscriber&rsquo;s responsibility to notify Haymarket of any changes to relevant personal information.</p>
                <p>
                  Subscriptions will NOT commence, and no magazine will be dispatched until the approval of the signed order form or upon receipt of payment.</p>
                <p>
                  For single or bulk subscription orders, a transfer of subscription to a new named user/subscriber is permitted one time per year in the circumstances of department change, office move or leaving the company.</p>
                <p>
                  For unlimited user subscription, you may add or take off users throughout the whole subscription period without incurring additional fees. New users will have access to content for the remainder of the subscription period.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>MISUSE OF ACCOUNTS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Including the multi-user licence offered by Haymarket,&nbsp;each user name and password is for a single named user only. It is not allowed to share the username and password with another person or allow multiple users to access digital content using the named subscriber&rsquo;s credentials. If we suspect access details have been shared with a third party, we reserve the right to terminate or restrict access to the digital content without prior notification or refund. Should you require an additional account for your colleagues please contact our subscription team on Tel: +852 2122 5222 or Email:&nbsp;<a href="mailto:subscriptions@asianinvestor.net">subscriptions@asianinvestor.net</a></p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>TERMINATION</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  There is <strong>NO </strong>refund for termination of subscription. Subscription products cannot be exchanged for different products, subscription or otherwise. Claims over two months for undelivered copies and past issues will not be entertained.</p>
              </Typography>
                            
              <Typography variant="subtitle1" gutterBottom>ONLINE AND EMAGAZINE ACCOUNT ACTIVATION</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Account activation is usually made within two working days (excluding Saturdays, Sundays and Hong Kong Public Holidays) from the date of confirmation of the signed order approved by Haymarket or upon receipt of payment.</p>
              </Typography>       

              <Typography variant="subtitle1" gutterBottom>PAYMENT TERMS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Payment terms are within 30 days from signing the order, either by cheque, credit card, or bank transfer. Please note that we only accept cheques in Hong Kong dollars. The subscriber is required to pay all the associated bank charges when paying by bank transfer.</p>
                <p>
                  Failure to pay within the specified period, unless otherwise agreed by Haymarket Media Limited in writing, may incur additional late charges and interest fees.</p>
              </Typography>       

              <Typography variant="subtitle1" gutterBottom>CREDIT CARD PAYMENT POLICY</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Payments received and transacted online and offline via credit card or debit card are done so at your own control, and by your own actions. It is your sole responsibility to ensure that you use the appropriate payment method. All payments by credit cards are final. Once accepted, card payments cannot be reversed, refunded, or changed to other payment methods.</p>
              </Typography>


              <Typography variant="subtitle1" gutterBottom>USE OF DATA</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Unless otherwise stated in these Terms &amp; Conditions and the Personal Information Collection Statement we provide to subscribers when we collect their information, we do not share the personal information with third parties for marketing purposes without the consent of the users.</p>
                <p>
                  Use of the personal information gathered from users is covered in the Site&#39;s&nbsp;<RLink to="/privacy">Privacy Policy</RLink>.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>USE OF MATERIALS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  If Haymarket is properly credited, the permitted subscribers may reproduce and use small portions of material or articles, sourced from Haymarket publications or websites, for internal or interoffice use, distribution to clients, client memoranda and court filings on an occasional basis. Unless otherwise authorised by Haymarket, any routine or systematic distribution of content is strictly prohibited. Any article or information shared hereunder shall include all copyrights and other proprietary rights. The right set out herein is not to be used as a substitute for purchasing reprint rights from the copyright holder.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>USE OF MATERIALS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  If Haymarket is properly credited, the permitted subscribers may reproduce and use small portions of material or articles, sourced from Haymarket publications or websites, for internal or interoffice use, distribution to clients, client memoranda and court filings on an occasional basis. Unless otherwise authorised by Haymarket, any routine or systematic distribution of content is strictly prohibited. Any article or information shared hereunder shall include all copyrights and other proprietary rights. The right set out herein is not to be used as a substitute for purchasing reprint rights from the copyright holder.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>SITE TRANSACTIONS WITH THIRD PARTIES</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Third party partners of Haymarket may offer reports, consultancy services, transactional services or other information and Products for sale on the Site. When users purchase such Products on the Site their contract is with the third party and not with Haymarket.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>COMPETITIONS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Haymarket will offer competitions and prize draws on the Site from time to time. Such competitions and prize draws will be subject to the Terms and Conditions published along with these competitions and prize draws.</p>
              </Typography>
              
              <Typography variant="subtitle1" gutterBottom>SOFTWARE</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Your use of any Software on the Site will be governed by the terms of the licence agreement that is included with that software.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>DISCLAIMER</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  The content of the information and reports contained on the Site are for general information purposes only and the information should be independently verified before relying on the same. The information does not constitute any form of advice by Haymarket for users to act or refrain from making any specific decision. In particular, Haymarket and its directors accept no responsibility for any loss resulting from any matter contained on the Site.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>NO WARRANTIES</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Haymarket does not give any warranties including but not limited to any warranties in relation to the accuracy or completeness of any of the content, information or reports available in respect of the Site or in respect of any material and reports made available by any third party partners through the Site. The Site and the content, information and reports available on the Site are provided on an as is basis and we hereby disclaim all warranties and duties of whatsoever nature express or implied including without limitation any implied warranties of merchantability or fitness for a particular purpose. In addition Haymarket makes no warranty that the Site is free from any viruses.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>LIMITATION ON DAMAGES</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Users of this Site agree that Haymarket will not be liable to them or any third party for any damages that they may incur in relation to the use of the Site or any services available thereon.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>CHANGES TO THE SITE AND TERMS AND CONDITIONS</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  Haymarket reserves the right at its sole discretion to make changes to the Site and the Terms and Conditions. Any use of the Site after the Terms and Conditions have been amended will be in accordance with the new Terms and Conditions and use of the Site after the Terms and Conditions have been amended will confirm a user&#39;s acceptance of the new Terms and Conditions.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>GOVERNING LAW</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  These Terms and Conditions shall be governed by and construed in accordance with the laws of the Hong Kong Special Administrative Region.</p>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>GENERAL</Typography>
              <Typography variant="body1" gutterBottom>
                <p>
                  If any provisions of these Terms and Conditions are found to be invalid by any court having jurisdiction then the invalidity of that provision will not affect the validity of the remaining provisions of these Terms and Conditions which shall remain in full force and effect.</p>
              </Typography>
                          
          </Box>
        </Grid>
      );
    case '/privacy':
      return(
        <Grid container alignItems="flex-start" direction="column" justify="center" className={classes.textContainer}>
          <Box>
            <Typography variant="h2" className={classes.title}>Privacy Policy</Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                This is the Hong Kong Privacy Policy of Haymarket Media Ltd.&nbsp; (Haymarket)</p>
              <p>
                Our contact details are as follows: 10/F, Zung Fu Industrial Building, No.1067 King&rsquo;s Road, Quarry Bay, Hong Kong. &nbsp;Tel: +852 3118 1500 and Fax: + 852 2968 0755.</p>
              <p>
                We have a Data Protection Officer who is responsible for matters relating to data protection and privacy, who can be reached at <strong><a href="mailto:HKdataprotectionofficer@haymarket.asia">HKdataprotectionofficer@haymarket.asia</a></strong></p>
              <p>
                Haymarket is committed to protecting your privacy. We have developed this Privacy Policy to explain our practices in processing your information when you visit our websites or use the services provided by us. We adhere to the Privacy by Design principles and take personal privacy into consideration from the start throughout the process of designing, developing, and delivering our products and services.</p>
              <p>
                We have taken care to abide by the Personal Data (Privacy) Ordinance, Chapter 486 of the Laws of Hong Kong, to collect, store and use Personal Data fairly and lawfully and with due respect to the individual whose Personal Data is being processed.</p>
              <p>
                By signing up to this website you agree to abide by this Privacy Policy and our <RLink to="/terms"><strong><u>Terms and Conditions</u></strong></RLink>.&nbsp; If you change your contact details you must let us know, and if you move to another country, the Data Protection rules may be different from those governing our Hong Kong websites.&nbsp; If you provide any Personal Details on another of our websites, you will be covered by the Privacy Policy and Terms and Conditions found on those sites. &nbsp;Please check regularly that you understand the guarantees we provide for you if you register with any of our sites that are not governed under Hong Kong Data Protection law.</p>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>Our brands</Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                <a href="http://www.asianinvestor.net/page/privacy" target="_blank">AsianInvestor</a></p>
              <p>
                <a href="https://www.campaignasia.com/page/privacypolicy" target="_blank">Campaign Asia</a></p>
              <p>
                <a href="https://www.thecorporatetreasurer.com/page/privacy" target="_blank">Corporate Treasurer</a></p>
              <p>
                <a href="https://www.financeasia.com/Page/Privacy.aspx" target="_blank">FinanceAsia</a></p>
            </Typography>
            
            <Typography variant="subtitle1" gutterBottom>What information do we collect from you?</Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                The definition of Personal Data in the Hong Kong Personal Data (Privacy) Ordinance is as follows:</p>
              <p>
                <em>&ldquo;Personal Data&rdquo; means any data:</em></p>
              <p>
                <em>(a)&nbsp;&nbsp; Relating directly or indirectly to a living individual;</em></p>
              <p>
                <em>(b)&nbsp;&nbsp; From which it is practicable for the identity of the individual to be directly or indirectly ascertained; and</em></p>
              <p>
                <em>(c)&nbsp;&nbsp;&nbsp; In a form which access to or processing of the data is practicable.</em></p>
              <p>
                It may be a single piece of information or a group of information taken together.&nbsp; We may collect personal data from you including your name, job title, company, address, telephone and mobile numbers, e-mail address, country of residence, gender, login ID as well as any information about you that is associated with, or linked to, any other data that helps us to provide you with a more personal and relevant experience.</p>
              <p>
                If you provide Haymarket with your Personal Data, it will help us to deliver to you the news, products or services, which you have requested.&nbsp;You can choose not to provide us with certain information, but this may stop you from gaining access to a service or limit the features that you can use on our websites. We will collect your contact information and we will note your interests so as to provide you with a better service and enrich your experience when you visit our websites.</p>
              <p>
                <strong>How does Haymarket collect your information?</strong></p>
              <p>
                We collect Personal Data from you when you register with one of our Hong Kong sites or when you choose to use the various services we provide.&nbsp; We also collect other types of data which may be considered personal that you provide to us voluntarily when, for instance, you make a purchase or respond to surveys and questionnaires, competitions, forums, social networking activities or other offers.</p>
              <p>
                <strong>How will Haymarket use your information?</strong></p>
              <p>
                We will use your information for the following purposes:</p>
            </Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <ol >
                <li>
                  to provide you with the products and services you have requested and to communicate with you about those products and services;</li>
                <li>
                  to deliver the daily operation of the content, services and products provided to you;</li>
                <li>
                  to design services or products for customers&rsquo; use;</li>
                <li>
                  with your consent we will let you know about any products or services which Haymarket believes may be of interest to you;&nbsp;you will always be able to unsubscribe or opt-out of receiving these messages.</li>
                <li>
                  with your consent we will work with our business partners who will send you marketing messages that we have selected (because we think you&rsquo;ll find them interesting) including, but not limited to, Business Solutions, Education &amp; training, Events, Finance &amp; investment, Media &amp; Marketing, Subscriptions, Surveys &amp; Research, Consumer Electronics, Automotive, Sport, and Travel &amp; Leisure.&nbsp; You will always be able to unsubscribe or opt-out at any time.&nbsp; These business arrangements help us to save you money and allow us to provide some of our services for free.</li>
                <li>
                  to invite you to share your opinions and comments;</li>
                <li>
                  to conduct a survey and/or market research.</li>
                <li>
                  we may also be required to disclose your Personal Data to authorities who can request this information by law that is binding on Haymarket, for instance, for the prevention and detection of crime, the capture or prosecution of offenders and the assessment or collection of tax or duty</li>
                <li>
                  purposes relating to the above.</li>
              </ol>
            </Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                <strong>Do we share your information with other companies?</strong></p>
              <p>
                We may share your Personal Data with our third party suppliers who need to process your data in order to fulfil your request, such as to charge your credit card (if authorised by you), fill your order, and deliver electronic newsletters and promotional messages. But they are required, under a contract with severe penalties, not to use your Personal Data other than to provide the services we request of them.&nbsp; Before we select our processing suppliers, whether within Hong Kong or elsewhere, they must satisfy us that they match our stringent processes to ensure the security of your data, whilst it is in their care. We regularly monitor them to ensure consistency of service and security.</p>
              <p>
                In addition, if you choose to receive certain content we make available or participate in any of our conferences or events, we may share your Personal Data with the co-organizers and partners, content providers and related vendors in connection with such content and programs. We may also share your Personal Data with our parent company and affiliates for internal business purposes. You understand that the level of data protection in countries outside of Hong Kong may not be equal to the level of data protection under the Hong Kong Personal Data (Privacy) Ordinance and that Haymarket will take appropriate measures to ensure an adequate level of protection is provided when transferring data internationally. You expressly consent to the disclosure of your Personal Data with our third-party service providers for the purposes of providing these services to you.</p>
              <p>
                <strong>How do we keep your Personal Data safe?</strong></p>
              <p>
                Wherever your Personal Data is processed, Haymarket will take the utmost care in keeping your data secure.&nbsp;&nbsp; We use a variety of industry-standard security technologies and procedures to help protect your Personal Data from unauthorised access or disclosure. We store data behind a firewall, which is a barrier designed to prevent outsiders from accessing our servers.&nbsp; Even though Haymarket takes significant steps to ensure that your Personal Data is not intercepted, accessed, used or disclosed by unauthorised persons, you should know that we cannot eliminate security risks entirely particularly where the internet is concerned.&nbsp; Rest assured that we always behave professionally and take particular care with regard to the protection of your Personal Data.</p>
              <p>
                <strong>How long do we keep your Personal Data?</strong></p>
              <p>
                Your Personal Data will only be retained by Haymarket for as long as is legitimately necessary for the continuation of our offering to you, or as prescribed by appropriate legislation.</p>
              <p>
                <strong>How can you change your mind about receiving our marketing messages?</strong></p>
              <p>
                We will always offer you choices about receiving marketing messages from us or our trusted business partners.&nbsp; When you register on this or any other of our websites, or respond to one of our communications or offers, we will ask for your consent of whether you want to receive future marketing messages from us.&nbsp; You can always unsubscribe to any of our emails by clicking on the unsubscribe link provided at the bottom of each communication we send you, or email us anytime at <a href="mailto:HKdataprotectionofficer@haymarket.asia"><strong>HKdataprotectionofficer@haymarket.asia</strong></a>&nbsp;or write to us at the address given in this Privacy Policy.</p>
              <p>
                Despite your indicated preferences we may send you customer service communications or notice of changes to our Privacy Policy and our Terms and Conditions.</p>
              <p>
                <strong>How can you access and correct information about you?</strong></p>
              <p>
                If you would like to see what information we hold about you, you will need to complete the Data Access Request Form issued by the Privacy Commissioner (OPS003) and specify clearly the detail of the personal data you would like to view. If you cannot do this on your own behalf you must give authorisation in writing for a person to do this&nbsp;on your behalf.</p>
              <p>
                We may request proof of identity before we undertake to disclose personal data. If we do not hold any Personal Data about you, we will confirm that fact in writing. We will respond to a valid request within one month after receiving the same.&nbsp;We will supply the information in an intelligible form so that it is easy for you to understand.</p>
              <p>
                If you would like to update your personal information, or correct an error or omission in the data we hold about you, you are welcome to contact our Customer Services Team at <a href="mailto:cs@haymarket.asia">cs@haymarket.asia</a> . We shall make the necessary changes without undue delay and provide you with a copy of the corrected information.&nbsp; Or, if we are unable to make the requested changes we will advise you of the reasons why we are unable to do so. If at any point you wish us to delete your account or any information about you, please submit your request to <a href="mailto:HKdataprotectionofficer@haymarket.asia">HKdataprotectionofficer@haymarket.asia</a> and we will do so within a reasonable period of time.</p>
              <p>
                You may also exercise your right to data portability and request us to provide you with an electronic file of your information, or to transfer your personal data to a third party if such is technically feasible.</p>
              <p>
                However, there are certain cases where we may need to retain some of your personal data in order to satisfy our legal obligations, or where we have a legitimate reason for doing so.</p>
              <p>
                <strong>Social media</strong></p>
              <p>
                Social plugins are buttons, boxes, and stories (such as the Like button) that websites can use to present relevant content to you and create more social and personal experiences for you. While you view these buttons, boxes, and stories on other sites, the content comes directly from the social plugin owner.</p>
              <p>
                We work with trusted third parties, social networks and with application developers who specialise in social media so we can connect to your social networks.&nbsp; These companies operate third party websites. We provide third parties and business partners with access to our sites so that we can generate interest in our products and services among members of your social networks. This also allows you to share product and service with friends in your social networks.</p>
              <p>
                We cannot control how your data is collected, stored, used or shared by third party social media websites or to whom the data is disclosed.&nbsp; Please be sure to review their Privacy Policies, Cookie Policies, and their Terms and Conditions to make sure you understand the information they are sharing.&nbsp; If you have questions you should contact that website to check your privacy settings and the rules that govern your ability to opt-out.&nbsp; Haymarket is not responsible for how these third parties may use information collected from or about you.</p>
              <p>
                <strong>Other websites</strong></p>
              <p>
                Except as otherwise stated in this Privacy Policy or on this website, only Haymarket collects data on this website.&nbsp; When you click on any links to external websites you will be leaving this site and you will go to another site where you are bound by their Privacy Policy and Terms and Conditions.&nbsp; This Privacy Policy, consequently, no longer applies.</p>
              
            </Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                <strong>Cookies and associated technologies</strong></p>
              <p>
                This site uses cookies. Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are helpful because they allow a website to recognise a user&rsquo;s device. They do lots of different jobs, like letting you navigate between pages, efficiently, remembering your preferences and generally improve the user experience.</p>
              <p>
                When we talk about cookies and tracking technology on our websites, we refer to:</p>
              <ul>
                <li>
                  Cookies</li>
                <li>
                  Flash Cookies</li>
                <li>
                  Web Beacons</li>
                <li>
                  Pixels</li>
                <li>
                  Log files</li>
                <li>
                  Other tracking technologies</li>
              </ul>
            </Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                The categories of cookies we use are as follows:</p>
              <p>
                <strong>Category 1: Strictly necessary cookies.</strong>&nbsp;These cookies are essential, as they enable you to move around a website and use its features, such as accessing secure areas. Without these cookies, services you&#39;ve asked for (such as access to secure areas) can&#39;t be provided. These cookies don&#39;t gather information about you that can be used for marketing or remembering where you&#39;ve been on the internet.</p>
              <p>
                <strong>Category 2: Performance cookies.</strong>&nbsp;These cookies collect information about how you use a website, for example which pages you go to most often and if you get any error messages from certain pages. These cookies don&#39;t gather information that identifies you. All information these cookies collect is anonymous and is only used to improve how our website works. These cookies are not used to target you with online advertising. Without these cookies we can&#39;t learn how our website is performing and make relevant improvements that could better your browsing experience.</p>
              <p>
                <strong>Category 3: Functionality cookies.</strong>&nbsp;These cookies allow a website to remember choices you make (such as your user name, language or the region you&#39;re in) and tailor the website to provide enhanced features and content for you. For instance, they can be used to remember log-in details, changes you&#39;ve made to text size, font and other parts of pages that you can customise. They may also be used to provide services you&#39;ve asked for such as watching a video or commenting on a blog. These cookies may be used to ensure that all our services and communications are relevant to you. The information these cookies collect cannot track your browsing activity on other websites. Without these cookies, a website cannot remember choices you&#39;ve previously made or personalise your browsing experience.</p>
              <p>
                <strong>Category 4: Targeting cookies or advertising cookies.</strong>&nbsp;These cookies gather information about your browsing habits. They remember that you&#39;ve visited a website and share this information with other organisations such as advertisers. They do this in order to provide you with ads that are more relevant to you and your interests. Although these cookies can track your visits to other websites, they don&#39;t usually know who you are. Without these cookies, online advertisements you encounter will be less relevant to you and your interests.</p>
              <p>
                <strong>Managing your Cookies</strong></p>
              <p>
                Most browsers automatically accept cookies by default, but you can choose to refuse cookies or selectively accept certain cookies by adjusting the preferences in your browser. Please note that if you do elect to disable your web browser&rsquo;s ability to accept cookies, you may not be able to access or take advantage of many features of the service and some parts of the website may not work properly.</p>
              <p>
                You can control how cookies are set within your browser settings. Please refer to your browser manufacturer for instructions on how to do this.</p>
              <p>
                <ul>
                  <li>
                    <a target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences?redirectlocale=en-US&amp;redirectslug=Enabling+and+disabling+cookies">Firefox</a></li>
                  <li>
                    <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></li>
                  <li>
                    <a target="_blank" href="https://support.microsoft.com/en-hk/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer &amp; Windows</a></li>
                  <li>
                    <a target="_blank" href="https://support.apple.com/en-hk/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari</a></li>
                </ul>
              </p>
              <p>
                You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser&rsquo;s cookies files may also clear certain optout cookies, plugins, or settings.</p>
            </Typography>
            <Typography variant="body1" component={'div'} gutterBottom>
              <p>
                <strong>Website Analytics</strong></p>
              <p>
                We may collect information about your computer and your visits to this site such as your IP address, geographical location, browser type, referral source, length of visit and number of page views. We may use this information to improve the website&rsquo;s usability and for other marketing purposes.</p>
              <p>
                We work directly with a number of technology partners to maintain and support our website, such as Google Analytics and Google DoubleClick, to allow tracking technologies and remarketing services on the Site and our mobile application through the use of first party cookies and third &shy;party cookies, to analyse and track users&rsquo; use of the Site and our mobile application, measure the effectiveness of our online marketing campaigns, determine the popularity of certain content, and better understand online activity. &nbsp;We may use the personal information we have about you to provide anonymous and statistical data to third party regarding the use of our websites, services, applications and tools. We do not share personally identifiable information with third party partners and customers for measurement and analytics services.</p>
              <p>
                By accessing the Site and our mobile application, you consent to the collection and use of your information by these third &shy;party vendors. These could set cookies or track your activity anonymously, you are encouraged to review their privacy policy and contact them directly if you do not want any information to be collected and used by these tracking technologies.</p>
              <p>
                <strong>Changes to this Privacy Policy</strong></p>
              <p>
                We may amend this Privacy Policy from time to time.&nbsp; If we make material changes, we will email you at the last email address that you provided to us.&nbsp; Changes become effective immediately.&nbsp; Our Privacy Policy will be dated to make it easier for you to check for changes.</p>
              <p>25<sup>th</sup> May 2018</p>
            </Typography>
          </Box>
        </Grid>
      );
    
    default:
      return(
        <Grid container alignItems="center" direction="column" justify="center" className={classes.container}>
          <Box>
            <Typography align="center">Page Not Found</Typography>
          </Box>
        </Grid>
      );
  }
}

const DynamicPage = (props) => {
  const location = useLocation();
  //console.log(location.pathname);
  const classes = useStyles();
  return (
    <CompanyProvider value={{setRedirectFunc:null}}>
      <Layout searchBar={false} >
        <div className={classes.root}>
          <Container maxWidth={'lg'} >
            {renderPage(location.pathname, classes)}
          </Container>
        </div>
      </Layout>
    </CompanyProvider >
  );  
};


export default DynamicPage;
