import React, { useState, useEffect, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Container, makeStyles, Grid, CircularProgress, Button, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Backdrop,
  FormControl, FormHelperText, Select, MenuItem, Box,Chip
  } from '@material-ui/core';
import { useParams, useLocation, Link } from "react-router-dom";
import { DataGrid, ColDef } from '@material-ui/data-grid';
import { Filter, useAsyncHook, FilterCheckBox, AssetFilters, AssetList } from '../components';
import Layout from '../layouts/Layout';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';

import { CompanyProvider } from '../components/CompanyContext';
import clsx from 'clsx';
//import Results from './Results';
//https://auth0.com/blog/complete-guide-to-react-user-authentication/
//https://github.com/auth0-samples/auth0-express-api-samples/tree/master/01-Authorization-RS256


const useStyles = makeStyles((theme) => ({
  root: {
    //minHeight: '90vh',
  /*    overflowY: 'auto',
      */
      // height:'100vh',
      // overflow: 'auto'
  /*    height: 'calc(100vh + 160px)',
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh + 200px)',
      },
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh + 250px)',
      }*/
  },

  title:{
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
  },
  filters:{
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    padding: theme.spacing(0.7, 2),
    backgroundColor:'#ececec',
    borderBottomRightRadius:'5px',
    borderBottomLeftRadius:'5px',
  },
  resultText:{
    marginBottom:theme.spacing(3),
    marginTop: theme.spacing(3),
    '& span':{
      color:theme.palette.secondary.main
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  textWrapper:{
  },
  results:{
    width: '100%'
  }, 
  
  table:{
    width: '100%',
    minWidth:'600px',
    '& a':{width:'100%'}
  },
  bgTable:{
    width: '100%',
    '& a':{width:'100%'}
  },
  header:{
    minWidth:'170px',
    padding: '6px 10px'
  },
  cell:{
    padding: '6px 10px'
  },
  loader:{
    minHeight:200,
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
  },
  iconMargin: {
    margin: 0,
    padding: '3px 1px'
  },
  paging:{
    marginTop: theme.spacing(5),
    marginBottom:theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  noRows:{
    textAlign:'right',
    [theme.breakpoints.down('sm')]: {
      display:'flex',

    }
  },
  rowsForm:{
    width:'80px',
    [theme.breakpoints.down('sm')]: {
      width:'100%'
    }
  }, 
  rowsTxt:{color:'#777'},
  tableRow:{
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'#efefef'
    }
  },
  topLinks:{
    marginBottom:theme.spacing(1),
    padding:theme.spacing(0,1),
    '& a':{
      fontSize:theme.typography.pxToRem(14),
      '&.active':{
        fontWeight:'bold',
        textDecoration:'underline',
        color:theme.palette.primary.dark
      }
    }
  },
  assetList:{
    backgroundColor:'#fff',
  }

}));



const ResultPage = () => {
  const preventDefault = (event) => event.preventDefault();
  let { keywords } = useParams();
  let query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  let displayStr = '', assetsFilters = [];

  const classes = useStyles();
  //const [rows, setData] = useState([]);
  //const [request, setRequest] = useState(undefined);

  const filterKeys = {
    country_facet:'country',
    typeOfInstitution_facet:'typeOfInstitution',
    //assetClasses_facet:'AssetClass'
  };

  //"flAssetClassesId_facet" = "flAssetClassesId"

  const sortKeys = {
    country:'countrySort',
    name:'nameSort',
    typeOfInstitution:'intSort',
    aum:'aumSort',
  };

    /*** Paramm Querying */
    let initAssetsGrps = [];
    if(query.get("flAssetGrps")){
      initAssetsGrps = decodeURIComponent(query.get("flAssetGrps"));
      //console.log(tempAssetsGrps, tempAssetsGrps.split(','));
      //console.log(typeof(tempAssetsGrps.split(',')));
      initAssetsGrps = initAssetsGrps.split(',');
    }
    if(query.get("flAssetClassesId")){
      let tempAssets = decodeURIComponent(query.get("flAssetClassesId"));
      tempAssets = JSON.parse(tempAssets);
      assetsFilters = tempAssets.map(o=>{
        return JSON.stringify({NameID:o.n, TypeNameID:o.t})
      })
    }



  const initialFilters = {
    "country_facet":query.get(filterKeys["country_facet"])?query.get(filterKeys["country_facet"]).split(','):[],
    "typeOfInstitution_facet":query.get(filterKeys["typeOfInstitution_facet"])?query.get(filterKeys["typeOfInstitution_facet"]).split(','):[],
    //"assetClasses_facet":query.get(filterKeys["assetClasses_facet"])?query.get(filterKeys["assetClasses_facet"]).split(','):[],
    "flAssetClassesId_facet":assetsFilters
  };
  const emptyFilters = {
    "country_facet":[],
    "typeOfInstitution_facet":[],
    //"assetClasses_facet":[],
    "flAssetClassesId_facet":[]
  };
  const initialSorts = {
    curr:query.get('sort')||'name',
    name:(query.get('sort')==='name')?query.get('sortBy'):(!query.get('sort'))?'ASC':'',
    country:(query.get('sort')==='country')?query.get('sortBy'):'',
    typeOfInstitution:(query.get('sort')==='typeOfInstitution')?query.get('sortBy'):'',
    aum:(query.get('sort')==='aum')?query.get('sortBy'):'',
  };
  const emptySorts = {
    curr:'',
    name:'',
    country:'',
    typeOfInstitution:'',
    aum:''
  };
  

  const initCurrPage = parseInt(query.get("currPage")) || 1;
  const initNumRows = parseInt(query.get("numRows")) || 10;


  const [resultReq, setResultReq] = useState(undefined);
  const [searchBody, setSearchBody ]= useState({});
  const [resultData, loadingResultData] = useAsyncHook(resultReq);

  const [numRows, setRows ]= useState(initNumRows);
  const [currPage, setPage ]= useState(initCurrPage);

  const [redirectTo, setRedirect] = useState('');
  const [selectedAssetGrps, setSelectedAssetsGrp] = useState(initAssetsGrps||[]);
  const [selectedAssetNames, setSelectedAssetNames] =useState([]); // 'Fixed Income','Cash...'
  const [selectedAssets, setSelectedAssets] =useState(assetsFilters||[]);
  const [searchKeywords, setKeywords] = useState(keywords?decodeURIComponent(keywords):'');
  const [assetError, setAssetError] = useState(undefined);

  const [assetListingData, setAssetListingData] = useState();


  

  /*** Filtering */
  
  const filterReducer = (state, action) => {
    switch (action.name) {
      case 'Countries': 
        return {...state,"country_facet":action.value};
      case 'Type of institutions': 
        return {...state,"typeOfInstitution_facet":action.value};
      case 'Asset classes': 
        return {...state,"assetClasses_facet":action.value};
      case 'Clear all': 
        return emptyFilters;
      default: 
        return initialFilters;
    }
  };

  const [filters, handleFilterChange] = useReducer(filterReducer, initialFilters);

  const handleYear = (date)=>{
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let insitutionDate= new Date(date);
    let insitutionYear = insitutionDate.getFullYear();
    const thirtyDaysInMs = 90 * 24 * 60 * 60 * 1000;
    const timeDiffInMs = currentDate.getTime() - insitutionDate.getTime();

if(timeDiffInMs >= thirtyDaysInMs){
  return false;
}else{
  return true
}
  }
  const sortReducer = (state, action) => {
    //console.log(action, state);
    switch (action) {
      case 'name': 
        return {...emptySorts,
          curr:"name",
          name:(state.name==='ASC')?'DESC':'ASC'};
      case 'country': 
        return {...emptySorts,
          curr:"country",
          country:(state.country==='ASC')?'DESC':'ASC'};
      case 'typeOfInstitution': 
        return {...emptySorts,
          curr:"typeOfInstitution",
          typeOfInstitution:(state.typeOfInstitution==='ASC')?'DESC':'ASC'};
      case 'aum': 
        return {...emptySorts,
          curr:"aum",
          aum:(state.aum==='ASC')?'DESC':'ASC'};
      default: 
        return initialSorts;
    }
  };

  const [currSort, handleSortingChange] = useReducer(sortReducer, initialSorts);
/*   const handleAssetSelect = (value)=>{
    setPreAssets(value);
  } */


  const handleApplyFilters = (event)=>{
    //console.log(preSelectedAssets, tempSelectedAssets);
    let params = '';
    //console.log(selectedAssetGrps, selectedAssets );
    if(selectedAssets.length===0){
      setAssetError({assetClassesVnFilter:'Please select at least 1 filter'});
      return;
    }
    params = getParamsUrl(null,selectedAssets, selectedAssetGrps);

    if(params!==''){
      setAssetError(null);
      history.push({
        pathname:(searchKeywords!=='')?`/results/${searchKeywords}`:'/results',
        search:'?'+params
      });
    }
    
  }
  const handleClearFilter = (event)=>{
    handleFilterChange({name:'Clear all'});
    handleSortingChange('');
    setSelectedAssets([]);
    setSelectedAssetsGrp([]);
    setSelectedAssetNames([])

  }
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const setRowsHandler = (event) => {
    setRows(event.target.value);
  }

  /*** Params */

  const getParamsUrl = (page, latestAssets, assetGrps)=>{
    let str = "", assets= '';
    //console.log(filters,currSort, numRows, currPage );
    
    for (let key in filters) {
      if(filters[key]?.length>0){
        let _filters = filters[key].map(o=>encodeURIComponent(o)).join(',');
        if (str !== "") 
          str += "&";
        str += filterKeys[key] + "=" + _filters;
      }
    }
    for (let key in currSort) {
      if(currSort[key]!=='' && key!=='curr'){
        if (str !== "") 
          str += "&";
        //str += sortKeys[key] + "=" + encodeURIComponent(currSort[key]);
        str += "sort=" + encodeURIComponent(currSort.curr) + "&sortBy="+ encodeURIComponent(currSort[key]);
      }
    }
    //console.log(assetGrps);
    if (assetGrps?.length>0){
      if (str !== "") 
        str += "&";
      str += "flAssetGrps="+encodeURIComponent(assetGrps);
    }
 /*    if (assetGrps.length>0){
      if (str !== "") 
        str += "&";
      str += "flAssetGrps="+encodeURIComponent(assetGrps.join(","));
    } */
    assets = latestAssets.map(o=>{
      let temp = JSON.parse(o);
      return {n:temp.NameID, t:temp.TypeNameID};
    })
    if (assets.length>0){
      if (str !== "") 
        str += "&";
      str += "flAssetClassesId="+encodeURIComponent(JSON.stringify(assets));
    }

    if (str !== "") 
      str += "&";
    if(page)
      str += "currPage=" + encodeURIComponent(currPage);
    else
      str += "currPage=" + encodeURIComponent(1);
    if (str !== "") 
      str += "&";
    str += "numRows=" + encodeURIComponent(numRows);
    //console.log(str)
    return str;
  }


  useEffect(()=>{
    //console.log(filters, searchKeywords, (initialFilters!==filters), (initialSorts !== currSort));
    /*if(filters.country_facet===''&& 
      filters.typeOfInstitution_facet==='' && 
      filters.assetClasses_facet==='' && 
      (searchKeywords==="" || searchKeywords==='null'))
      return;
    else */
    
    let params;
    if(initialFilters!==filters || initialSorts !== currSort || numRows !==initNumRows || currPage !== initCurrPage){
      console.log('xx', selectedAssets, selectedAssetGrps, filters);
      if(currPage !== initCurrPage)
        params = getParamsUrl('page', selectedAssets, selectedAssetGrps);
      else
        params = getParamsUrl(null, selectedAssets, selectedAssetGrps);
      //let url = (searchKeywords!=='')?`/results/${searchKeywords}`:'/results';
      //console.log(url, params);return;
      history.push({
        pathname:(searchKeywords!=='')?`/results/${searchKeywords}`:'/results',
        search:'?'+params
      });
    }else{
      setResultReq({ 
        method: 'POST',
        authorized: true,
        url: process.env.REACT_APP_API_URL+'/Institutions/Search/', 
        type: "application/json",
        body:{
          keyword:searchKeywords || "", 
          sort:currSort[currSort.curr],
          rows:numRows,
          page:currPage, 
          orderByFieldName:(currSort.curr!=='aum')?currSort.curr+'_sort':'aum',
          facetFilters:filters
        }
      });
    }
  },[filters, searchBody, currSort,numRows, currPage, searchKeywords]);

  // useEffect(()=>{
  //   if(selectedAssets?.length>0 && selectedAssetGrps?.length>0){
  //     console.log(selectedAssets, selectedAssetGrps)
  //   }
  //   console.log(resultData);
    
  // },[resultData])


  displayStr = searchKeywords||"";

  //console.log(filters);

  if(filters.country_facet!==''){
    if(displayStr==='')
      displayStr += 'Country: '+filters.country_facet;
    else
      displayStr += ', Country: '+filters.country_facet;
  }
  if(filters.typeOfInstitution_facet!==''){
    if(displayStr==='')
      displayStr += 'Type of Institution: '+filters.typeOfInstitution_facet;
    else
      displayStr += ', Type of Institution: '+filters.typeOfInstitution_facet;
  }
  

 /* if(filters.assetClasses_facet!==''){
    if(displayStr==='')
      displayStr += 'AssetClass: '+filters.assetClasses_facet;
    else
      displayStr += ', AssetClass: '+filters.assetClasses_facet;
  }*/

  //console.log(resultData, filters, currSort, numRows, currPage);
  //numFound //page
  if(redirectTo!==''){
    return <Redirect push to={redirectTo} />
  }else{
    let noPages = 1;
    if(resultData && resultData.numFound)
      noPages = Math.ceil(resultData.numFound / numRows);

    //console.log(assetError);
    //console.log(loadingResultData, resultData);
    return (
      <CompanyProvider value={{setRedirectFunc:setRedirect, searchKeywords:searchKeywords, assetError:assetError }}>
        <Layout searchBar={true} path={'results'}>
          <div className={classes.root}>
            <Container maxWidth={'lg'}>
              <Grid container className={classes.filters}>
                <Grid container spacing={2} className={classes.topLinks}>
                  <Grid item >
                    <Link to={''} className={clsx('active')} onClick={preventDefault}>Institution</Link>
                  </Grid>
                  <Grid item >
                    <Link to={`/insight/${searchKeywords}`}>Investment strategy</Link>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={3} >
                    {resultData && 
                    <FilterCheckBox 
                      label={true}
                      multiple
                      onSelect={handleFilterChange} 
                      selectedItem={(filters.country_facet!=='')?filters.country_facet:[]} 
                      items={resultData.countries?resultData.countries:[]} 
                      id={'countriesFilter'}
                      filterName={'Countries'}/>
                    }
                  </Grid>
                  <Grid item xs={12} md={4} >
                    {resultData && 
                    <FilterCheckBox
                      label={true}
                      multiple
                      onSelect={handleFilterChange} 
                      items={resultData.typeOfInstitutions?resultData.typeOfInstitutions:[]} 
                      selectedItem={(filters.typeOfInstitution_facet!=='')?filters.typeOfInstitution_facet:[]} 
                      id={'institutionsFilter'}
                      filterName={'Type of institutions'}/>
                    }
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    {resultData && 
                      <AssetFilters 
                        data={resultData} 
                        selectedItems={selectedAssetNames} 
                        selectedAssets={selectedAssets} 
                        initAssetGroups={selectedAssetGrps} 
                        error={assetError} 
                        onAssetListingChange={setAssetListingData} 
                        onSelectedAssetsGrp={setSelectedAssetsGrp} 
                        //onSelectedAssetNames={handleFilterChange}
                        onSelectedAssetNames={setSelectedAssetNames} />
                    }
                  </Grid>
                  {assetListingData?.length>0 && 
                    <Grid item xs={12} className={classes.assetList}>  
                      <AssetList 
                        data={assetListingData} 
                        selectedItems={selectedAssets} 
                        onSelectItems={setSelectedAssets}/>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Box mt={2} mb={2}>
                      <Grid container justify="flex-end" alignItems="center" spacing={1}>
                        <Grid item >
                          <Button disabled={(selectedAssets?.length>0)?false:true}  onClick={handleApplyFilters} size="small" variant="contained" color="primary">Apply</Button>
                        </Grid>
                        <Grid item >
                          <Button onClick={handleClearFilter} size="small" variant="outlined">Reset</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                
              </Grid>
              
              <Grid container className={classes.textWrapper} justify="space-between" alignItems="center" direction="row">
                <Grid item >
                  <Typography variant="h6" className={classes.resultText} >
                    The result(s) of '<span>{displayStr}</span>'
                  </Typography>
                </Grid>
                <Grid item className={classes.noRows}>
                  <FormControl className={classes.rowsForm}>
                    <Select
                      value={numRows}
                      onChange={setRowsHandler}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <FormHelperText className={classes.rowsTxt}>No of results</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {(loadingResultData && resultData.length===0)?
                <Backdrop className={classes.backdrop} open={loadingResultData} >
                  <CircularProgress color="inherit" />
                </Backdrop>
                :
                <div className={classes.results}>
                  {(resultData.institutions && resultData.institutions.length!==0 && !loadingResultData)?
                    <TableContainer>
                      <Table className={classes.table} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.header} align="left">Institution
                              <IconButton onClick={(e)=>handleSortingChange('name')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.name==='ASC')?
                                  <ArrowDropUpIcon color="primary" fontSize="inherit" />
                                  :(currSort.name==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary" fontSize="inherit" />}
                                
                              </IconButton>
                            </TableCell>
                            <TableCell className={classes.header} align="left">Country
                              <IconButton  onClick={(e)=>handleSortingChange('country')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.country==='ASC')?
                                  <ArrowDropUpIcon color="primary"  fontSize="inherit" />
                                  :(currSort.country==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary"  fontSize="inherit" />}
                              </IconButton>
                            </TableCell>
                            <TableCell className={classes.header} align="left">Institution type
                              <IconButton onClick={(e)=>handleSortingChange('typeOfInstitution')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.typeOfInstitution==='ASC')?
                                  <ArrowDropUpIcon color="primary"  fontSize="inherit" />
                                  :(currSort.typeOfInstitution==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary"  fontSize="inherit" />}
                              </IconButton>
                            </TableCell>
                            <TableCell className={classes.header} align="right">AUM (billions USD)
                              <IconButton onClick={(e)=>handleSortingChange('aum')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.aum==='ASC')?
                                  <ArrowDropUpIcon color="primary"  fontSize="inherit" />
                                  :(currSort.aum==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary"  fontSize="inherit" />}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {resultData.institutions.map((row) => (
                              <TableRow key={row.id} onClick={e => setRedirect('/institution/'+row.id)} className={classes.tableRow}>
                              <TableCell className={classes.cell} align="left">{row.name} {handleYear(row.dataAsOfDate) == true ?   <Chip className={classes.chip}  size="small" color="primary" label="New" />: ""}</TableCell>
                              <TableCell className={classes.cell} align="left">{row.country}</TableCell>
                              <TableCell className={classes.cell} align="left">{row.typeOfInstitution}</TableCell>
                              <TableCell className={classes.cell} align="right">{Number(row.aum).toFixed(2)}</TableCell>
                             
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid container justify="center" className={classes.paging}>
                        <Pagination count={noPages} page={resultData.page} onChange={handlePageChange} />
                      </Grid>
                    </TableContainer>/*<DataGrid onCellClick={handleCellClick} rows={resultData.institutions} columns={columns} />*/
                  :
                    <Typography variant="h6" align="center" >No Result</Typography>
                  }
                </div>
              }
            </Container>
          </div>
        </Layout>
      </CompanyProvider>
    );
  }
  
  
};

export default ResultPage;
