import React, { useEffect, useState }from 'react';
import { Typography, Container, makeStyles, Grid, Box} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useAsyncHook, Loading} from '../components';
import Layout from '../layouts/Layout';
import SearchBar from '../layouts/SearchBar';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { CompanyProvider } from '../components/CompanyContext';
import Moment from 'react-moment';
//import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3)
  },
  body:{
    padding: theme.spacing(2,0,2),
    minHeight: '400px'
  }
}));

const AccountPage = () => {
  let history = useHistory();
  const { isAuthenticated, user, loading } = useAuth0();

  const [redirectTo, setRedirect] = useState('');
  const [searchKeywords, setKeywords] = useState('');

  const classes = useStyles();
  useEffect(() => {
    const fn = async () => {
      if (!loading) {
        //console.log("handleRedirectCallback: " + loading);
        //await handleRedirectCallback();
      }else{
        //console.log(isAuthenticated, user);
      }
    };
    fn();
  }, [isAuthenticated, loading]);

  useEffect(()=>{
    if(searchKeywords!==''){
      //console.log(searchKeywords);
      history.push({
        pathname:(searchKeywords!=='')?`/results/${searchKeywords}`:'/results',
      });
    }
  },[searchKeywords])

  if(redirectTo!==''){
    return <Redirect push to={redirectTo} />
  }else{
    return (
      <CompanyProvider value={{setRedirectFunc:setRedirect, searchKeywords:searchKeywords }}>
        <Layout searchBar={true} path={'results'}>
          <div className={classes.root}>
            <Container maxWidth={'md'}  >
              <Grid container direction="column" justify="center" alignItems="center" className={classes.body}>
                <Grid item >
                  <Box mb={2}>
                    <Typography variant="h1" >
                      {user.nickname}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="h3" >
                      {user.email}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="h5" >
                      Expiry Day: <Moment format="YYYY-MM-DD">{user['http://haymarket/claims/expireddate']}</Moment>
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="h5" >
                      {'If you have any problem, please feel free to contact our sales'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Layout>
      </CompanyProvider>
    );
   }
};

export default AccountPage;
