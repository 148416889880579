import React, { useContext} from 'react';
import { Grid } from '@material-ui/core';
import { CompanyContext } from '../CompanyContext';

const FloatingMenu = () => {
  const {classes, menuItems, logoUrl} = useContext(CompanyContext);

/*  useEffect(() => {
    setAnchorTarget(document.getElementById(itemName));
  }, [itemName]);*/

  const handleClick = event => {
    event.preventDefault();
    let id = event.target.getAttribute('href').substring(1);
    let archor = document.getElementById(id);
    if(archor)
      archor.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Grid container direction='column' spacing={2} className={classes.floatingMenu}>
      {logoUrl &&
        <Grid item className={classes.menuLogo}>
          <img src="https://via.placeholder.com/600x400?text=Logo"/>        
        </Grid>
      }
      {menuItems.map((item) => {
        if(item.status==='show'){
          return <Grid key={item.id} item>  
            <a className={classes.menuItem} href={`#${item.id}`} onClick={handleClick} aria-label={`Scroll to ${item.name}`}>
              {item.name}
            </a>
          </Grid>
        }
      })}
    </Grid>
  )
}

export default FloatingMenu;