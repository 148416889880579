import React from 'react';
import { makeStyles, Grid, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader:{

  }
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid className={classes.loader}>
        <CircularProgress />
      </Grid>
    </div>
  );
};

export default Loading;