import React from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import Footer from './Footer';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    height: '100%',
    margin: '0 auto -142px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
/*    display: 'flex',
    flex: '1 1 auto',
    */
    overflow: 'auto',
    paddingTop: 64,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
    // flexGrow: 1,
/*    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),*/
  },
  contentContainer: {
    flex: '1 1 auto',
/*    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'*/
  },
  content: {
/*    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',*/
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


const Layout = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const drawerHandler = (isOpen) => {
    //console.log(isOpen);
    setOpen(isOpen);
  };
  return(
    <div className={classes.root}>
      <TopBar {...props} drawerHandler={drawerHandler} />
      <div className={clsx(classes.wrapper, {
          [classes.contentShift]: open,
        })}>
        <div className={classes.contentContainer}>
          {props.children}
        </div>
        <Footer/>
      </div>
      
    </div>
  );

};
/*
export default () => {
  const routerResult = useRoutes({
    "/": () => <SearchPage />
  });

  return <Layout>{routerResult}</Layout>;
};*/

export default Layout;