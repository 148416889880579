import React,  { useState, useEffect } from 'react';
import {
  Grid, makeStyles, TextField, IconButton, Chip, FormHelperText, FormControl, Link
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useAsyncHook } from '../components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const indicatorStyle = {
  backgroundColor: "transparent",
  '&:hover':{backgroundColor: "#333"}, 
  width:'20px',
  height:'20px',
  '& svg':{fontSize: "1rem"}
}
const useStyles = makeStyles((theme) => ({
  searchItem:{
  },
  searchIcon:{
    '& svg':{
      color: '#fff'
    }
  },
  searchBlackIcon:{
    '& svg':{
      color: '#000'
    }
  },
  searchBar:{
    flexGrow:3,
    [theme.breakpoints.down('md')]: {
      flexGrow:4
    }
  },
  inputStyle:{
    '& input':{color: '#fff'}, 
  },
  inputBlackStyle:{
    '& input':{color: '#000'}, 
    '& > div::after':{
      borderBottomColor: '#777 !important'
    }
  },
  clearIndicator: {
    ...indicatorStyle,
    color:'#fff',
  },
  blackIndicator:{
    ...indicatorStyle,
    color:'#000',
  },
  label:{
    marginRight:'5px',
    width:'90px'
  },
  resultText:{
    fontSize: theme.typography.pxToRem(12),
    color:'#4D91D1',
    '& span':{
      fontSize: theme.typography.pxToRem(12), 
      color:'#153451'
    },
    paddingTop:theme.spacing(0.73)
  },
  autoCompleteWrapper:{
    flexWrap:'nowrap'
  },
  input:{width:'100%'},
  chip:{
    width:'90px',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.85,0.85)'
    }
  }
}));


const SearchBar = (props) => {
  //console.log(props);
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(props.searchKeywords||'');
  const [request, setRequest] = useState(undefined);
  const [results, loadingData] = useAsyncHook(request);
  const [blank, setBlankError] = useState(false);
  const [searchUrl, setSearchUrl] = useState('');
  const {color, searchKeywords} = props;


  useEffect(() => {
    //if(inputValue!=='' && !loadingData){
    if(inputValue!=='' && inputValue!==null && inputValue!=='null'){
      setRequest({
        authorized:true,
        url: process.env.REACT_APP_API_URL+'/Institutions/AutoComplete/'+inputValue, 
        type: 'application/x-www-form-urlencoded;charset=UTF-8',
        method: 'GET'
      })
    }
  }, [inputValue]);

  useEffect(() => {
    //if(!loadingData)
    let params = (props.path==='insight' && inputValue==='')?'?sort=institutionIODateTime&sortBy=DESC':'';
    //console.log(searchUrl);
    props.submitSearch(searchUrl);
  }, [searchUrl]);

  const searchHandler = (e) =>{
    //console.log(encodeURI(`/${props.path}/`+inputValue)); return;
    //console.log('searchHandle',inputValue);
    if(inputValue!=='' && inputValue!==null && inputValue!=='null'){
      var params = (props.path==='insight' && inputValue==='')?'?sort=institutionIODateTime&sortBy=DESC':'';

      //console.log(`/${props.path}/`+encodeURIComponent(inputValue)+params);
      setSearchUrl(`/${props.path}/`+encodeURIComponent(inputValue)+params);
    }
      
  /*  else 
      setBlankError(true);*/
  }

  //console.log(results.autoSuggestions, loadingData);
    return (
      <Grid container spacing={1} alignItems="flex-end" className={classes.gridStyles}>
        <Grid item className={clsx((color==='white')?classes.searchBlackIcon:classes.searchIcon, classes.searchItem )}>
          <IconButton onClick={searchHandler} color="primary" aria-label="upload picture" component="span">
            <Search />
          </IconButton>
        </Grid>
        <Grid item className={clsx(classes.searchBar, classes.searchItem )}>
          <Autocomplete
            freeSolo={true}
            options={(results.autoSuggestions)?results.autoSuggestions:[]}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue||'');
              //setBlankError(false);
            }}
            value={{displayStr:inputValue , type: "", url: "results/"+inputValue}}
            getOptionLabel={(option) => option.displayStr?option.displayStr:''}
            className={classes.autoComplete}
            classes={{
              clearIndicatorDirty: (color==='white')?classes.blackIndicator:classes.clearIndicator
            }}
            onChange={(event, value) => {
              if(value && value.url)
                setSearchUrl('/'+value.url)
              else if(value && value!=='' && value!==null )
                setSearchUrl('/'+props.path+'/'+encodeURIComponent(value))


            }}
            filterOptions={x=>x}
            renderInput={(params) => (
              <FormControl className={classes.input} error={blank} >
                <TextField
                  {...params}
                  fullWidth={true}
                  placeholder={'Search Here'}
                  style={{ margin: 8, width: '90%' }}
                  color="secondary"
                  className={(color==='white')?classes.inputBlackStyle:classes.inputStyle}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.target.value) {
                      if(e.target.value.url)
                        setSearchUrl('/'+e.target.value.url);
                      else if(e.target.value!=='')
                        setSearchUrl('/'+props.path+'/'+encodeURIComponent(e.target.value));
                    }
                  }}
                />
                <FormHelperText style={{display: blank ? 'block' : 'none' }}>Cannot leave blank</FormHelperText>
              </FormControl>
            )}
            renderOption={(option, { inputValue }) => {
              //console.log(option, inputValue);
              const str = option.displayStr;
              const matches = match(str, inputValue);
              const parts = parse(str, matches);

              return (
                <Grid  className={classes.autoCompleteWrapper} container direction="row">
                  <Grid item className={classes.label}>
                    <Chip className={classes.chip} variant="outlined" size="small" label={option.type} />
                  </Grid>
                  <Grid item className={classes.resultText}>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  

};

export default SearchBar;
