import React from 'react';

const CompanyContext = React.createContext(null);

const CompanyProvider = (props) => {
  return (
    <CompanyContext.Provider value={props.value}>
      {props.children}
    </CompanyContext.Provider>
  );
}

export { CompanyContext, CompanyProvider };