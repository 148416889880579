import React, { useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";
import Moment from 'react-moment';

const InvsOutlook = (props) =>{
  const {institutionsInvestmentOutlookList: rows, classes} = useContext(CompanyContext);
  const first2 = rows && rows.length >0 ?rows.slice(0, 2) : [];
  /*const rows = [
    {name:'Frozen yoghurt',     jobtitle:'Director',office:'HK'},
    {name:'Ice cream sandwich', jobtitle:'Manager', office:'HK'},
    {name:'Eclair',             jobtitle:'CEO',     office:'HK'},
  ];*/
    return (
      <Grid id={props.id} container spacing={2} className={classes.section}>
        <Grid item xs={12} className={classes.subTitle}>
          <Typography variant="subtitle1" gutterBottom>
            {'Investment strategy in next 6-12 months'}
          </Typography>
          {/*<Typography variant="subtitle2" gutterBottom>
            {'Last update at:'} {'Dec 11, 2020'}
          </Typography>*/}
        </Grid>
        
        { rows && (rows.length>0) ? (
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell className="dateCell">Date</TableCell>
                    <TableCell align="left" className="assetsCell">Theme</TableCell>
                    <TableCell align="left">Strategy Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {props.isShow ? first2.map((row) => (
                    <TableRow key={row.pkInstitutionInvestmentOutlookId}>
                      <TableCell component="th" scope="row" >
                        <Moment format="YYYY-MM">
                          {row.date}
                        </Moment>
                      </TableCell>
                      <TableCell align="left">{row.assetClass}</TableCell>
                      <TableCell align="left">{row.strategyDetails}</TableCell>
                    </TableRow>
                  )) :rows.map((row) => (
                    <TableRow key={row.pkInstitutionInvestmentOutlookId}>
                      <TableCell component="th" scope="row" >
                        <Moment format="YYYY-MM">
                          {row.date}
                        </Moment>
                      </TableCell>
                      <TableCell align="left">{row.assetClass}</TableCell>
                      <TableCell align="left">{row.strategyDetails}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          ):(
          <Grid item xs={12}>
            <Typography gutterBottom>
              {'No Information is available'}
            </Typography>
          </Grid>
          )
        }

      </Grid>
    );

  
}

export default InvsOutlook;