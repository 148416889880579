import React, { useState, useEffect, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Container, makeStyles, Grid, CircularProgress, Button, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Backdrop,
  FormControl, FormHelperText, Select, MenuItem
  } from '@material-ui/core';
import { useParams, useLocation, Link } from "react-router-dom";
import { DataGrid, ColDef } from '@material-ui/data-grid';
import { Filter, useAsyncHook, FilterCheckBox } from '../components';
import Layout from '../layouts/Layout';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';

import { CompanyProvider } from '../components/CompanyContext';
import clsx from 'clsx';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  root: {
  },

  title:{
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
  },
  filters:{
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    padding: theme.spacing(0.7, 2),
    backgroundColor:'#ececec',
    borderBottomRightRadius:'5px',
    borderBottomLeftRadius:'5px',
  },
  resultText:{
    marginBottom:theme.spacing(3),
    marginTop: theme.spacing(3),
    '& span':{
      color:theme.palette.secondary.main
    },
    [theme.breakpoints.down('sm')]: {
    }
  },
  textWrapper:{
  },
  results:{
    width: '100%'
  }, 
  
  table:{
    width: '100%',
    minWidth:'600px',
    '& a':{width:'100%'}
  },
  bgTable:{
    width: '100%',
    '& a':{width:'100%'}
  },
  header:{
    minWidth:'170px',
    padding: '6px 10px'
  },
  cell:{
    padding: '6px 10px'
  },
  loader:{
    minHeight:200,
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
  },
  iconMargin: {
    margin: 0,
    padding: '3px 1px'
  },
  paging:{
    marginTop: theme.spacing(5),
    marginBottom:theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  noRows:{
    textAlign:'right',
    [theme.breakpoints.down('sm')]: {
      display:'flex',

    }
  },
  rowsForm:{
    width:'80px',
    [theme.breakpoints.down('sm')]: {
      width:'100%'
    }
  }, 
  rowsTxt:{color:'#777'},
  tableRow:{
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'#efefef'
    }
  },
  topLinks:{
    marginBottom:theme.spacing(1),
    padding:theme.spacing(0,1),
    '& a':{
      fontSize:theme.typography.pxToRem(14),
      '&.active':{
        fontWeight:'bold',
        textDecoration:'underline',
        color:theme.palette.primary.dark
      }
    }
  }

}));



const InsightPage = () => {
  const preventDefault = (event) => event.preventDefault();
  let { keywords } = useParams();
  let query = new URLSearchParams(useLocation().search);
  const emptyParams = (useLocation().search==='')?true:false;
  let history = useHistory();
  let displayStr = '';

  const classes = useStyles();
  //const [rows, setData] = useState([]);
  //const [request, setRequest] = useState(undefined);

  const filterKeys = {
    country_facet:'country',
    typeOfInstitution_facet:'typeOfInstitution',
    institutionIOAssetClasses_facet:'AssetClass',
  };

  const sortKeys = {
    country:'countrySort',
    institutionName:'nameSort',
    institutionIOStrategyDetails:'intSort',
    institutionIODateTime:'dateSort',
    institutionIOAssetClasses:'assetsSort'
  };

  const initialFilters = {
    "country_facet":query.get(filterKeys["country_facet"])?query.get(filterKeys["country_facet"]).split(','):[],
    "typeOfInstitution_facet":query.get(filterKeys["typeOfInstitution_facet"])?query.get(filterKeys["typeOfInstitution_facet"]).split(','):[],
    "institutionIOAssetClasses_facet":query.get(filterKeys["institutionIOAssetClasses_facet"])?query.get(filterKeys["institutionIOAssetClasses_facet"]).split(','):[]
  };
  const emptyFilters = {
    "country_facet":[],
    "typeOfInstitution_facet":[],
    "institutionIOAssetClasses_facet":[]
  };
  const initialSorts = {
    curr:query.get('sort')||'',
    institutionName:(query.get('sort')==='institutionName')?query.get('sortBy'):'',
    country:(query.get('sort')==='country')?query.get('sortBy'):'',
    institutionIOStrategyDetails:(query.get('sort')==='institutionIOStrategyDetails')?query.get('sortBy'):'',
    institutionIODateTime:(query.get('sort')==='institutionIODateTime')?query.get('sortBy'):'',
    institutionIOAssetClasses:(query.get('sort')==='institutionIOAssetClasses')?query.get('sortBy'):'',
  };
  const emptySorts = {
    curr:'',
    institutionName:'',
    country:'',
    institutionIOStrategyDetails:'',
    institutionIODateTime:'',
    institutionIOAssetClasses:''
  };

  const initCurrPage = parseInt(query.get("currPage")) || 1;
  const initNumRows = parseInt(query.get("numRows")) || 10;


  const [resultReq, setResultReq] = useState(undefined);
  const [searchBody, setSearchBody ]= useState({});
  const [resultData, loadingResultData] = useAsyncHook(resultReq);

  const [numRows, setRows ]= useState(initNumRows);
  const [currPage, setPage ]= useState(initCurrPage);

  const [redirectTo, setRedirect] = useState('');
  const [searchKeywords, setKeywords] = useState(keywords?decodeURIComponent(keywords):'');

  //const [filtersData, setFilters] = useState([]);
  
  const filterReducer = (state, action) => {
    //console.log(state,action);
    switch (action.name) {
      case 'Countries': 
        return {...state,"country_facet":action.value};
      case 'Institution Type': 
        return {...state,"typeOfInstitution_facet":action.value};
      case 'Theme': 
        return {...state,"institutionIOAssetClasses_facet":action.value};
      case 'Clear all': 
        return emptyFilters;
      default: 
        return initialFilters;
    }
  };

  const [filters, handleFilterChange] = useReducer(filterReducer, initialFilters);


  const sortReducer = (state, action) => {
    //console.log(action, state);
    

    switch (action) {
      case 'institutionName': 
        return {...emptySorts,
          curr:"institutionName",
          name:(state.institutionName==='ASC')?'DESC':'ASC'};

      case 'country': 
        return {...emptySorts,
          curr:"country",
          country:(state.country==='ASC')?'DESC':'ASC'};

      case 'institutionIODateTime': 
        return {...emptySorts,
          curr:"institutionIODateTime",
          institutionIODateTime:(state.institutionIODateTime==='ASC')?'DESC':'ASC'};

      case 'institutionIOAssetClasses':
        return {...emptySorts,
          curr:"institutionIOAssetClasses",
          institutionIOAssetClasses:(state.institutionIOAssetClasses==='ASC')?'DESC':'ASC'};

      default: 
        return initialSorts;
    }
  };

  const [currSort, handleSortingChange] = useReducer(sortReducer, initialSorts);
  const handleApplyFilters = (event)=>{
    //console.log(filters);
    //console.log(params);
    history.push({
      pathname:(searchKeywords!=='')?`/insight/${searchKeywords}`:'/insight',
      search:'?'+getParams()
    });
  }
  const handleClearFilter = (event)=>{
    handleFilterChange({name:'Clear all'});
    handleSortingChange('');
  }
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const setRowsHandler = (event) => {
    setRows(event.target.value);
  }

  const getParams = (page)=>{
    let str = "";
    //console.log(filters,currSort, numRows, currPage );
    
    for (let key in filters) {
      if(filters[key]!==''){
        let _filters = filters[key].map(o=>encodeURIComponent(o)).join(',');
        if (str !== "") 
          str += "&";
        str += filterKeys[key] + "=" + _filters;
      }
    }
    for (let key in currSort) {
      if(currSort[key]!=='' && key!=='curr'){
        if (str !== "") 
          str += "&";
        //str += sortKeys[key] + "=" + encodeURIComponent(currSort[key]);
        str += "sort=" + encodeURIComponent(currSort.curr) + "&sortBy="+ encodeURIComponent(currSort[key]);
      }
    }

    if (str !== "") 
      str += "&";
    if(page)
      str += "currPage=" + encodeURIComponent(currPage);
    else
      str += "currPage=" + encodeURIComponent(1);
    if (str !== "") 
      str += "&";
    str += "numRows=" + encodeURIComponent(numRows);
    //console.log(str)
    return str;
  }


  useEffect(()=>{
    //console.log(filters, searchKeywords, (initialFilters!==filters), (initialSorts !== currSort));
    /*if(filters.country_facet===''&& 
      filters.typeOfInstitution_facet==='' && 
      filters.assetClasses_facet==='' && 
      (searchKeywords==="" || searchKeywords==='null'))
      return;
    else */
    let params;
    if(initialFilters!==filters || initialSorts !== currSort || numRows !==initNumRows || currPage !== initCurrPage){
      if(currPage !== initCurrPage)
        params = getParams('page');
      else
        params = getParams();
      //let url = (searchKeywords!=='')?`/results/${searchKeywords}`:'/results';
      //console.log(url, params);return;

      if(emptyParams && searchKeywords==='')
        params = 'sort=institutionIODateTime&sortBy=DESC';
      history.push({
        pathname:(searchKeywords!=='')?`/insight/${searchKeywords}`:'/insight',
        search:'?'+params
      });
    }else if(emptyParams && searchKeywords===''){
      history.push({
        pathname:'/insight',
        search:'?sort=institutionIODateTime&sortBy=DESC'
      });
    }else{
      setResultReq({ 
        method: 'POST',
        authorized: true,
        url: process.env.REACT_APP_API_URL+'/Insight/Search/', 
        type: "application/json",
        body:{
          keyword:searchKeywords || "", 
          sort:(emptyParams)?'DESC':(currSort.curr!=='')?currSort[currSort.curr]:"ASC",
          rows:numRows,
          page:currPage, 
          //orderByFieldName:'score',
          orderByFieldName:(emptyParams)?'institutionIODateTime':(currSort.curr==='')?'score':(currSort.curr==='institutionIODateTime' || currSort.curr==='institutionIOAssetClasses')?currSort.curr:currSort.curr+'_sort',
          facetFilters:filters
        }
      });
    }
  },[searchBody, currSort,numRows, currPage, searchKeywords]);
  //},[searchBody, filters, currSort,numRows, currPage, searchKeywords]);

/*  useEffect(()=>{
    let params = getParams();
    history.push({
      pathname:'/results',
      search:'?'+params
    });
  }, [])*/

  displayStr = searchKeywords||"";

  //console.log(currSort);

  if(filters.country_facet!==''){
    if(displayStr==='')
      displayStr += 'Country: '+filters.country_facet;
    else
      displayStr += ', Country: '+filters.country_facet;
  }
  if(filters.typeOfInstitution_facet!==''){
    if(displayStr==='')
      displayStr += 'Institution Type: '+filters.typeOfInstitution_facet;
    else
      displayStr += ', Institution Type: '+filters.typeOfInstitution_facet;
  }

  if(filters.institutionIOAssetClasses_facet!==''){
    if(displayStr==='')
      displayStr += 'Theme: '+filters.institutionIOAssetClasses_facet;
    else
      displayStr += ', Theme: '+filters.institutionIOAssetClasses_facet;
  }

  //console.log(resultData, filters, currSort, numRows, currPage);
  //numFound //page
  //console.log(loadingResultData, resultData);
  if(redirectTo!==''){
    return <Redirect push to={redirectTo} />
  }else{
    let noPages = 1;
    if(resultData && resultData.numFound)
      noPages = Math.ceil(resultData.numFound / numRows);
    return (
      <CompanyProvider value={{setRedirectFunc:setRedirect, searchKeywords:searchKeywords }}>
        <Layout searchBar={true} path={'insight'}>
          <div className={classes.root}>
            <Container maxWidth={'lg'} >
              <Grid container className={classes.filters}>
                <Grid container spacing={2} className={classes.topLinks}>
                  <Grid item >
                    <Link to={`/results/${searchKeywords}`}>Institution</Link>
                  </Grid>
                  <Grid item >
                    <Link to={``} className={clsx('active')} onClick={preventDefault}>Investment strategy</Link>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={3} >
                    {resultData && 
                    <FilterCheckBox 
                      label={true}
                      multiple
                      onSelect={handleFilterChange} 
                      selectedItem={(filters.country_facet!=='')?filters.country_facet:[]} 
                      items={resultData.countries?resultData.countries:[]} 
                      filterName={'Countries'}/>
                    }
                  </Grid>
                  <Grid item xs={12} md={4} >
                    {resultData && 
                    <FilterCheckBox 
                      label={true}
                      multiple
                      onSelect={handleFilterChange} 
                      items={resultData.typeOfInstitutions?resultData.typeOfInstitutions:[]} 
                      selectedItem={(filters.typeOfInstitution_facet!=='')?filters.typeOfInstitution_facet:[]} 
                      filterName={'Institution Type'}/>
                    }
                  </Grid>
                  <Grid item xs={12} md={3} >
                    {resultData && 
                    <FilterCheckBox
                      label={true}
                      multiple
                      onSelect={handleFilterChange} 
                      items={resultData.institutionIOAssetClasses?resultData.institutionIOAssetClasses:[]} 
                      selectedItem={(filters.institutionIOAssetClasses_facet!=='')?filters.institutionIOAssetClasses_facet:[]} 
                      filterName={'Theme'}/>
                    }
                  </Grid>
                  <Grid item xs={12} md={2} >
                    <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                      <Grid item>
                        <Button onClick={handleApplyFilters} size="small" variant="contained" color="primary">Apply</Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={handleClearFilter} size="small" variant="outlined">Reset</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.textWrapper} justify="space-between" alignItems="center" direction="row">
                <Grid item >
                  <Typography variant="h6" className={classes.resultText} >
                    The result(s) of '<span>{displayStr}</span>'
                  </Typography>
                </Grid>
                <Grid item className={classes.noRows}>
                  <FormControl className={classes.rowsForm}>
                    <Select
                      value={numRows}
                      onChange={setRowsHandler}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <FormHelperText className={classes.rowsTxt}>No of results</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {(loadingResultData && resultData.length===0)?
                <Backdrop className={classes.backdrop} open={loadingResultData} >
                  <CircularProgress color="inherit" />
                </Backdrop>
                :
                <div className={classes.results}>
                  {(resultData.insights && resultData.insights.length!==0 && !loadingResultData)?
                    <TableContainer>
                      <Table className={classes.table} size="small">
                        <TableHead>
                          <TableRow>

                            <TableCell className={classes.header} align="left">Institution
                              <IconButton onClick={(e)=>handleSortingChange('institutionName')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.institutionName==='ASC')?
                                  <ArrowDropUpIcon color="primary" fontSize="inherit" />
                                  :(currSort.institutionName==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary" fontSize="inherit" />}
                                
                              </IconButton>
                            </TableCell>

                            

                            <TableCell className={classes.header} align="left">Country
                              <IconButton  onClick={(e)=>handleSortingChange('country')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.country==='ASC')?
                                  <ArrowDropUpIcon color="primary"  fontSize="inherit" />
                                  :(currSort.country==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary"  fontSize="inherit" />}
                              </IconButton>
                            </TableCell>

                            <TableCell className={classes.header} align="left">Date
                              <IconButton  onClick={(e)=>handleSortingChange('institutionIODateTime')} aria-label="delete" className={classes.iconMargin} size="small">
                                {(currSort.institutionIODateTime==='ASC')?
                                  <ArrowDropUpIcon color="primary"  fontSize="inherit" />
                                  :(currSort.institutionIODateTime==='')? <ArrowDropUpIcon fontSize="inherit" color="disabled" />
                                    :<ArrowDropDownIcon color="primary"  fontSize="inherit" />}
                              </IconButton>
                            </TableCell>
                            <TableCell className={classes.header} align="left">Theme</TableCell>
                            <TableCell className={classes.header} align="left">Investment Strategy</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {resultData.insights.map((row) => (
                              <TableRow key={row.id} onClick={e => setRedirect('/institution/'+row.salesforceAccountId)} className={classes.tableRow}>
                              <TableCell className={classes.cell} align="left">{row.institutionName}</TableCell>
                              <TableCell className={classes.cell} align="left">{row.country}</TableCell>
                              <TableCell className={classes.cell} align="left">
                                <Moment format="YYYY-MM">
                                  {row.institutionIODateTime}
                                </Moment>
                              </TableCell>
                              <TableCell className={classes.cell} align="left">{row.institutionIOAssetClasses.join(', ')}</TableCell>
                              <TableCell className={classes.cell} align="left">{row.institutionIOStrategyDetails}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid container justify="center" className={classes.paging}>
                        <Pagination count={noPages} page={resultData.page} onChange={handlePageChange} />
                      </Grid>
                    </TableContainer>/*<DataGrid onCellClick={handleCellClick} rows={resultData.institutions} columns={columns} />*/
                  :
                    <Typography variant="h6" align="center" >No Result</Typography>
                  }
                </div>
              }
            </Container>
          </div>
        </Layout>
      </CompanyProvider>
    );
  }
  
  
};

export default InsightPage;
