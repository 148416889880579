import React, {useState, useEffect} from 'react';
import { Typography, Container, makeStyles, Grid, Avatar, Link,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle , Box 
  } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Link as RLink } from 'react-router-dom';
import LoginButton from '../components/LoginButton';
import LogoutButton from '../components/LogoutButton';
import { deepOrange } from '@material-ui/core/colors';
import logo from '../assets/images/AI-Inverted.png';
import Aoilogo from '../assets/images/Red-White.png';
import aoiHero from '../assets/images/home-bg.jpg';

import InstitutionIcon from '../assets/images/icons/Institution.png';
import InsightsIcon from '../assets/images/icons/Insights.png';
import TragetedContactsIcon from '../assets/images/icons/Targeted-Contacts.png';
import DollarIcon from '../assets/images/icons/AOI_Icon_Dollar.png';

import InvestorsIcon from '../assets/images/icons/Investors.png';
import KeyContactsIcon from '../assets/images/icons/Key-Contacts.png';
import MarketsAnalysisIcon from '../assets/images/icons/Markets-Analysis.png';
import PortfolioTrendsIcon from '../assets/images/icons/Portfolio-Trends.png';
import NewMarketsIcon from '../assets/images/icons/New-Markets.png';


import AustraliaIcon from '../assets/images/icons/Australia@4x.png';
import ChinaIcon from '../assets/images/icons/China@4x.png';
import HongKongIcon from '../assets/images/icons/HongKong@4x.png';
import JapanIcon from '../assets/images/icons/Japan@4x.png';
import KoreaIcon from '../assets/images/icons/Korea@4x.png';
import SingaporeIcon from '../assets/images/icons/Singapore@4x.png';
import TaiwanIcon from '../assets/images/icons/Taiwan@4x.png';
import ThailandIcon from '../assets/images/icons/Thailand@4x.png';


import { typeformEmailAssets } from '../assets/scripts/typeformEmailAssets.js';


import queryString from 'query-string';
import clsx from 'clsx';

import Footer from '../layouts/Footer';

const useStyles = makeStyles((theme) => ({
  body:{
    height: 'calc(100vh - 94px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 110px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 152px)',
    }

  },
  header:{
    backgroundColor:'#000000',
    padding: '5px 0'
  },
  hero:{
    // backgroundImage:`url(${aoiHero})`,
    // backgroundRepeat:"no-repeat",
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // height:'80vh'
    backgroundColor:'#000000',
    position: 'relative',

    '&:after': {
      content: '""',
      top: '0',
      right: '0',
      bottom: '0',
      position: 'absolute',
      borderTop: '90px solid transparent',
      borderRight: '90px solid #ED1C24',
      borderBottom: '88px solid #ED1C24',
      borderLeft: '88px solid transparent'
    }
  },
  container:{
    flexWrap:'nowrap',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    padding:theme.spacing(7, 0, 10), 
    '& ul':{
      // marginLeft:theme.spacing(3), 
    },
    '& li':{
      marginBottom:theme.spacing(2), 
    }
  },
  customContainer: {
    maxWidth: '1170px'
  },
  paragraphText: {
    fontSize:theme.typography.pxToRem(20),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
  },
  // sectionHero:{
  //   height:'100%',
  //   '& h2':{
  //     color:'#ffffff'
  //   }
  // },
  sectionTitle:{
    fontWeight:800, 
    textAlign:'center',
    '&.textRed':{
      color:'#ED1C24'
    }
  },

  subscriptionLink: {
    color: '#fff',
    fontWeight: 700,
    borderRadius: '4px',
    padding: '10px 15px',
    display: 'inline-block',
    backgroundColor: '#ED1C24',
    fontSize:theme.typography.pxToRem(20),
    width: '400px'
  },

  subscriptionLinkBottom: {
    color: '#fff',
    fontWeight: 700,
    borderRadius: '4px',
    padding: '10px 15px',
    display: 'inline-block',
    backgroundColor: '#ED1C24',
    fontSize:theme.typography.pxToRem(20),
    width: '400px',
    marginTop: '53px',
    marginBottom: '-12px'
  },

  sectionTitleLeft:{
    fontWeight:600, 
    textAlign:'left',
    fontSize:theme.typography.pxToRem(26),
    '&.textRed':{
      color:'#ED1C24'
    }
  },
  redBulletList: {

    '&.bullet-with-mb': {

      '& li': {
        marginBottom: '15px'
      }
    },

    '& li': {
      fontSize:theme.typography.pxToRem(20),
      listStyle: 'none',
      lineHeight: '1.5',
      marginBottom: '0',
      position: 'relative',
      padding: '0 0 0 15px',
      
      '&:after': {
        left: '0',
        right: '0',
        top: '12px',
        bottom: '0',
        width: '3px',
        content: "''",
        height: '3px',
        position: 'absolute',
        backgroundColor: 'red',
        borderRadius: '100%',
      }
    },
    
  },
  menu:{
    flexGrow: 2,
    '& a':{
      color:'#fff',
      '&:hover':{
        color:'#ED1C24'
      }
    }
  },
  title:{
    marginBottom:theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize:theme.typography.pxToRem(15),
    }
  },
  logoSloganText: {
    color: '#fff',
    fontWeight: '600',
    fontSize:theme.typography.pxToRem(16),
  },
  siteLogo:{
    height: '220px',
    marginBottom:theme.spacing(2),
    '& img':{
      'transition': 'transform 0.3s ease-in',
    },
    '&:hover':{
      '& img':{
        transform: 'translateY(-10px)',
        opacity:0.5
      },
      '& p':{
        opacity:1,
      },
      '& a':{
        textDecoration:'none'
      }
    },
    '& p':{
      'transition': 'opacity 0.3s ease-in',
      transform: 'translateY(-50px)',
      width:'100%',
      opacity:0,
      textAlign:'center',
      textDecoration:'none'

    }
  },
  aiLogo:{
    // margin:theme.spacing(3, 0, 3),
    maxWidth: '200px',
    '& img':{
      width:'100%'
    }
  },
  aoiLogo:{
    maxWidth:'350px',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'350px',
      width:'100%'
    }
  },
  startSearch:{
    marginTop:theme.spacing(4),
  },
  profile:{
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& button':{
      borderRadius:0,
      color:'#ffffff',
      backgroundColor:'#8a8a8a',
      fontWeight:'bold',
      padding: '2px 20px',
      textTransform:'none',
      fontSize:theme.typography.pxToRem(15),
    }
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  }, 
  dialogTitle:{
    fontSize:'20px',
    margin:theme.spacing(2, 0, 2)
  },
  dialogDesc:{
    color:'#000'
  },
  dialogBody:{
    minWidth:'600px'
  },
  greyBgColor:{
    backgroundColor:'#ececec'
  },
  greyDarkBgColor:{
    backgroundColor:'#a7a7a7',
    color:'#353535'
  },
  redBgColor:{
    backgroundColor:'#ED1C24', 
    color:'#fff'
  },
  whiteBgColor:{
    backgroundColor:'#fff', 
  },
  redCircle:{
    width:'80px',
    height:'80px',
/*    backgroundColor:'#ED1C24',
    borderRadius: '50%', */
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    '& img':{
      width:'100%'
    }
  },
  number:{
    color:'#ED1C24',
    fontSize:theme.typography.pxToRem(20),
    fontWeight:'800'
  },
  countryNum:{
    fontSize:theme.typography.pxToRem(60),
    fontWeight:'800', 
    borderBottom:'#000 1px solid',
  },
  countryIcon:{
    display:'flex',
    height:'100px',
    justifyContent:'center',
    alignItems:'center',
    // marginTop:'10px',
    '& img':{
      maxWidth:'100px',
      maxHeight:'80px',
      filter: 'contrast(0)'
    }
  },
  country:{
    textTransform:'upperCase',
    color:'#000',
    fontSize: theme.typography.pxToRem(20),
    fontWeight:'600',
    textAlign:'center'
  },
  keyIcon:{
    width:'80px',
    height:'80px',
    marginTop: '0',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: theme.spacing(2),
    '& img':{
      objectFit: 'contain',
      width: '100%',
      height:'100%'
    },
  },
  keyName:{
    fontWeight:'600',
    textAlign:'center',
    fontSize: theme.typography.pxToRem(16),
  },
  keyItem:{

  },
  iconText:{
    [theme.breakpoints.down('sm')]: {
      minWidth:'200px'
    }
  },
  dfButton:{
    textTransform:'upperCase',
    fontWeight:'bold',
    borderRadius:0,
    minWidth:'200px',
    fontSize:theme.typography.pxToRem(18),
    
  },
  'blackButton':{
    backgroundColor:'#000',
    color:'#fff',
    '&:hover':{
      backgroundColor:'#555'
    }
  }
}));

const HomePage = () => {
  const classes = useStyles();
  const [dialogData, setDialog] = useState({title:'',desc:'','open':false});
  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const params = queryString.parse(window.location.search);
  const { isAuthenticated, user, isLoading, loginWithRedirect, logout, error, getAccessTokenSilently} = useAuth0();
  const handleClose = () => {
    setDialog({...dialogData,open:false});
  };
  const showProfile = (name) =>{
    return <Avatar className={classes.orange}>{name.substr(0,1).toUpperCase()}</Avatar>
  }

  const handleContactClick = (id)=>{
    const tfPopup = window.typeformEmbed.makePopup(
      "https://form.typeform.com/to/zjYrgODY?typeform-medium=embed-snippet",
      {
        mode: 'popup',
        autoClose: 3000,
        hideHeaders: true,
        hideFooters: true
      }
    );
    tfPopup.open();
    //createPopup('Nodfw3g8?typeform-medium=embed-snippet#sfcid='+id).open();
    //createPopup('Nodfw3g8').open();
  }

  useEffect(()=>{
    if(params.error&& params.error_description){
      setDialog({
        open:true,
        //title:decodeURIComponent(params.error_description), 
        title:"Your subscription has been expired or not subscribed yet",
        desc:'Thank you for your interest in Asset Owner Insight. The following content is for paid subscribers only. Please contact our sales representatives for more details.'
      })
    }else if(isAuthenticated && user){
       const pageUrl = localStorage.getItem('PageUrl');
       setRedirectUrl(pageUrl);
      setRedirect(true);
      
    }
  },[]);
  if(redirect){
    if(redirectUrl!=null)
    {
      return <Redirect to={redirectUrl}/>
    }
    else{
      return <Redirect to={'/search'}/>
    }
   
  }{
    return (
      <div>
        <Container className={classes.body} disableGutters={true} maxWidth={false}>
          <Container id="header" className={classes.header} disableGutters={true} maxWidth={false}>
            <Container maxWidth={'lg'}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item className={classes.menu}>
                  <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item>
                      <a href="#about-aoi">About AOI</a>
                    </Grid>
                    <Grid item>
                      <a href="#why-aoi">Why AOI</a>
                    </Grid>
                    <Grid item>
                      <Link onClick={handleContactClick}>Contact AOI</Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.profile}>
                  {(isAuthenticated!==undefined && !isLoading && user ) ?
                      <>
                        {showProfile(user.nickname)}
                        <LogoutButton/>
                      </>
                    : (params?.error && (params.error ==='unauthorized' || params.error==='access_denied')) ?
                      <LogoutButton/>
                    : <LoginButton/>
                  }
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Container>
          <Container disableGutters={true} maxWidth={false} className={classes.hero}>
            <Container className={classes.customContainer}>
              {/* <Grid container direction="column" justify="center" alignItems="center" className={classes.sectionHero}>
                <Grid className={classes.aiLogo}>
                  <img src={logo}/>
                </Grid>
                <Grid item className={classes.siteLogo}>
                  <Link href="/search">
                    <img className={classes.aoiLogo} src={Aoilogo}/>
                    <Typography className={classes.startSearch}>Start Searching</Typography>
                  </Link>
                </Grid>
                <Grid>
                  <Typography align="center" variant="h1" component="h2" gutterBottom>
                    Which asset owner are you looking for?
                  </Typography>
                </Grid>
                <Grid>
                  <Box mt={4}>
                    <Button onClick={handleContactClick} variant="contained" color="primary" className={classes.dfButton}>Get Started</Button>
                  </Box>
                </Grid>
              </Grid> */}
              <Box pt={3} pb={2}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Link href="/search">
                      <img className={classes.aoiLogo} src={Aoilogo} />
                      <Typography className={classes.logoSloganText}>Asia’s one-stop investment intelligence platform</Typography>
                    </Link>
                  </Grid>
                  <Grid className={classes.aiLogo}>
                    <img src={logo} />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Container>
          
          <Container id="about-aoi" disableGutters={true} maxWidth={false} className={clsx(classes.container, classes.whiteBgColor)}>
            <Container className={classes.customContainer}>
              {/* Asset Owner Section Starts */}
              <Box mb={4} align="center">
                <Link href="https://asianinvestor.net/account/subscribe" target="blank" className={classes.subscriptionLink}>Subscription</Link>
              </Box>
              <Box mb={4}>
                <Grid>
                  <Typography variant="h3" gutterBottom className={classes.sectionTitleLeft}>
                    Asset Owner Insights (AOI) is <i>AsianInvestor</i>’s unique portal designed to enhance transparency within the region’s institutional asset management landscape. Find out more details from here and if you’re interested, please contact our subscription team <a href="mailto: subscriptions@asianinvestor.net">subscriptions@asianinvestor.net</a> or  <a href="tel:+852 2122 5222">+852 2122 5222</a>.
                  </Typography>
                </Grid>
              </Box>
   
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box mb={3}>
                    <Typography className={classes.paragraphText} gutterBottom>
                      In addition to key up-to-date data on AUM and portfolio allocation by asset class and geography, we have leveraged our relationships and access to the most influential investors to capture and reveal proprietary views and other content on Asia’s leading institutions. This includes:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ul className={classes.redBulletList}>
                    <li>Forward looking allocation plans</li>
                    <li>Market and asset class-specific sentiment</li>
                    <li>Third-party mandates awarded</li>
                    <li>Key investment contacts</li>
                    <li>News on portfolio activities and best practices</li>
                  </ul>
                </Grid>
              </Grid>
              <Box mt={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Box mr={3} className={classes.redCircle}>
                        <img src={InsightsIcon} />
                      </Box>
                    </Grid>
                    <Grid item className={classes.iconText}>
                      <Box>
                        <Typography>INSIGHTS ON</Typography>
                        <Typography className={classes.number}>100+</Typography>
                        <Typography>ASSET OWNERS</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Box mr={3} className={classes.redCircle}>
                        <img src={TragetedContactsIcon}/>
                      </Box>
                    </Grid>
                    <Grid item className={classes.iconText}>
                      <Box>
                        <Typography className={classes.number}>2,500</Typography>
                        <Typography>INVESTOR</Typography>
                        <Typography>CONTACTS</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Box mr={3} className={classes.redCircle}>
                        <img src={DollarIcon}/>
                      </Box>
                    </Grid>
                    <Grid item className={classes.iconText}>
                      <Box>
                        <Typography>DATA FOR</Typography>
                        <Typography className={classes.number}>450</Typography>
                        <Typography>ASSET MANAGERS</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Container maxWidth={'sm'}>
                  <Grid container  direction="row" justify="center" alignItems="center">
                    <Box mt={4} mb={4}>
                    
                    </Box>
                  </Grid>
                </Container>
              </Grid>
              </Box>
              {/* Asset Owner Section Ends */}
              {/* Geographic Section Starts */}
              <Grid container>
                <Grid>
                  <Box>
                    <Typography variant="h3" gutterBottom className={clsx([classes.sectionTitleLeft,'textRed'])}>
                      GEOGRAPHIC BREAKDOWN BY INSTITUTION
                    </Typography>
                  </Box>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={KoreaIcon}/>
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>S.Korea</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={HongKongIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>Hong Kong</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={AustraliaIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>Australia</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={ChinaIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>China</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={SingaporeIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>Singapore</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={ThailandIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>Thailand</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={JapanIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>Japan</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} lg={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box className={classes.countryIcon}>
                          <img src={TaiwanIcon} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.iconText}>
                        <Box>
                          <Typography className={classes.country}>Taiwan</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Box mb={3} sx={{ fontStyle: 'italic', m: 1 }}>
                    <Typography className={classes.paragraphText} align="center">
                      <i>All data and content can be filtered by <strong>asset class, geography</strong> and/or <strong>type of institution.</strong></i>
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid>
                  <Box mb={3} mt={4}>
                    <Typography variant="h3" gutterBottom className={classes.sectionTitle}>
                      AND MORE..
                    </Typography>
                  </Box>
                </Grid> */}
              </Grid>
              {/* Geographic Section Ends */}
              {/* Key Benefit Section Starts */}
              <Grid id="why-aoi" className={clsx(classes.greyBgColor)}>
                <Box p={2}>
                  <Grid>
                    <Typography variant="h3" gutterBottom className={clsx([classes.sectionTitleLeft,'textRed'])}>
                      KEY BENEFITS
                    </Typography>
                  </Grid>
                  <Typography className={classes.paragraphText} gutterBottom>
                    Against a macro, geopolitical and market backdrop where investment behaviour and approaches by asset owners to building and managing portfolio are changing, this gives fund management firms the intelligence they seek to grow their business.
                  </Typography>
                  <Box pt={3} pb={3}>
                    <Grid container direction="row" justify="space-around" alignItems="center">
                      <Grid item xs={6} sm={2} md={2}>
                        <Box className={classes.keyIcon}>
                          <img src={InvestorsIcon}/>
                        </Box>
                        <Typography className={classes.keyName}>Source investors for your funds</Typography>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <Box className={classes.keyIcon}>
                          <img src={PortfolioTrendsIcon}/>
                        </Box>
                        <Typography className={classes.keyName}>Identify portfolio trends</Typography>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <Box className={classes.keyIcon}>
                          <img src={NewMarketsIcon}/>
                        </Box>
                        <Typography className={classes.keyName}>Explore new markets for expansion</Typography>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <Box className={classes.keyIcon}>
                          <img src={MarketsAnalysisIcon}/>
                        </Box>
                        <Typography className={classes.keyName}>Conduct competitor and market analysis</Typography>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <Box className={classes.keyIcon}>
                          <img src={KeyContactsIcon}/>
                        </Box>
                        <Typography className={classes.keyName}>Uncover key contacts at target asset owners</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box mb={3}>
                        <Typography className={classes.paragraphText} gutterBottom>
                          To ensure the portal is regularly engaged by leading asset owners in the region, AOI is also designed to give them critical information – on a complimentary basis – on the activities of their peers and wider market trends.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className={classes.paragraphText} gutterBottom>
                        <i>AsianInvestor</i> believes this approach to understanding the entire investment ecosystem is the key to improving decision-making by making it more targeted, timely and relevant than ever before.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* Key Benefit Section Ends */}
              {/* Our Commitment Section Starts */}
              <Grid container>
                <Box mt={4}>
                  <Grid>
                    <Typography variant="h3" gutterBottom className={clsx([classes.sectionTitleLeft,'textRed'])}>
                      OUR COMMITMENT
                    </Typography>
                  </Grid>
                  <Typography className={classes.paragraphText} gutterBottom>
                    To ensure our coverage is accurate and comprehensive:
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6}>
                      <ul className={clsx([classes.redBulletList,'bullet-with-mb'])}>
                        <li>We continue to refine our <strong>methodology</strong> to ensure we cover the region’s most important and dynamic asset owners</li>
                        <li>We have expanded our <strong>in-house research team</strong> to ensure we can speak with more asset owners and cover more ground</li>
                        <li>We are deepening existing <strong>relationships</strong> with investors and establishing new ones as the industry evolves</li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <ul className={clsx([classes.redBulletList,'bullet-with-mb'])}>
                        <li>We have a <strong>dedicated focus</strong> on combing through publicly available data, official public statements and credible news outlets to supplement our insights</li>
                        <li>We will ensure we constantly review and <strong>refresh our research</strong> and approach to updating the data and content on AOI</li>
                      </ul>
                    </Grid>
                  </Grid>
                   {/* <Grid container justify="center">
                    <Box mt={4}>
                      <Button onClick={handleContactClick} variant="contained" className={clsx(classes.blackButton,classes.dfButton)}>Request a demo</Button>
                    </Box>
                  </Grid>*/}
                </Box>
                
              </Grid>
             
              {/* Our Commitment Section Ends */}
              {/* <Grid container direction="column" alignItems="center">
                <Grid>
                  <Box mb={3}>
                    <Typography variant="body1" align="center">
                      All data and content can be filtered by asset class, geography and/or type of institution.
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  <Box>
                    <Button onClick={handleContactClick} variant="contained" className={clsx(classes.blackButton,classes.dfButton)}>Discover Now</Button>
                  </Box>
                </Grid>
              </Grid> */}
            </Container>
            <Container>
          <Grid>
            <Box mb={6} align="center">
                <Link href="https://asianinvestor.net/account/subscribe" target="blank" className={classes.subscriptionLinkBottom}>Subscription</Link>
              </Box>
              </Grid>
          </Container>
          </Container>
          

          {/* <Container id="why-aoi" disableGutters={true} maxWidth={false} className={clsx(classes.container, classes.whiteBgColor)}>
            <Container maxWidth={'md'}>
              <Grid container justify="center">
                <Grid container direction="row" justify="space-around" alignItems="center"  spacing={3}>
                  <Grid item xs={12} md={4} className={classes.keyItem} >
                    <Grid container alignItems='center' direction='column'>
                      <Box className={classes.keyIcon}>
                        <img src={InvestorsIcon}/>
                      </Box>
                      <Typography className={classes.keyName}>Source investors for your funds</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.keyItem} >
                    <Grid container alignItems='center' direction='column'>
                      <Box className={classes.keyIcon}>
                        <img src={PortfolioTrendsIcon}/>
                      </Box>
                      <Typography className={classes.keyName}>Identify portfolio trends</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.keyItem} >
                    <Grid container alignItems='center' direction='column'>
                      <Box className={classes.keyIcon}>
                        <img src={NewMarketsIcon}/>
                      </Box>
                      <Typography className={classes.keyName}>Explore new markets for expansion</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.keyItem} >
                    <Grid container alignItems='center' direction='column'>
                      <Box className={classes.keyIcon}>
                        <img src={MarketsAnalysisIcon}/>
                      </Box>
                      <Typography className={classes.keyName}>Conduct competitor and market analysis</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.keyItem} >
                    <Grid container alignItems='center' direction='column'>
                      <Box className={classes.keyIcon}>
                        <img src={KeyContactsIcon}/>
                      </Box>
                      <Typography className={classes.keyName}>Uncover key contacts at target asset owners</Typography>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <Box mb={3} mt={8}>
                <Typography variant="body1">
                  To ensure the portal is regularly engaged by leading asset owners in the region, AOI is also designed to give them critical information – on a complimentary basis – on the activities of their peers and wider market trends.
                </Typography>
              </Box>
              <Box mb={8}>
                <Typography variant="body1">
                  Celebrating our 20<sup>th</sup> year, <i>AsianInvestor</i> believes this approach to understanding the entire investment ecosystem is the key to improving decision-making by making it more targeted, timely and relevant than ever before.
                </Typography>
              </Box>
              <Grid container direction="column" alignItems="center">
                <Grid>
                  <Box>
                    <Button onClick={handleContactClick} variant="contained" className={clsx(classes.blackButton,classes.dfButton)}>Try it for yourself</Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Container> */}

          {/* <Container disableGutters={true} maxWidth={false} className={clsx(classes.container, classes.greyDarkBgColor)}>
            <Container maxWidth={'md'}>
              <Grid container justify="center">
                <Grid>
                  <Box mb={3}>
                    <Typography variant="h3" gutterBottom className={clsx([classes.sectionTitle,'textRed'])}>
                      OUR COMMITMENT
                    </Typography>
                  </Box>
                  <Box mb={5}>
                    <Typography variant='h5'>To ensure our coverage is accurate and comprehensive</Typography>
                  </Box>
                </Grid>
                <Grid container  direction="row" justify="space-around" alignItems="flex-start"  spacing={3}>
                  <Grid item xs={12} md={6} >
                    <Box mb={5}>
                      <ul>
                        <li>We continue to refine our methodology to ensure we cover the region’s most important and dynamic asset owners</li>
                        <li>We have expanded our in-house research team to ensure we can speak with more asset owners and cover more ground</li>
                        <li>We are deepening existing relationships with investors and establishing new ones as the industry evolves</li>
                      </ul>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6} >
                    <Box mb={5}>
                      <ul>
                        <li>We have a dedicated focus on combing through publicly available data, official public statements and credible news outlets to supplement our insights</li>
                        <li>We will ensure we constantly review and refresh our research and approach to updating the data and content on AOI</li>
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center">
                  <Grid>
                    <Box>
                      <Button onClick={handleContactClick} variant="contained" className={clsx(classes.blackButton,classes.dfButton)}>Request a demo</Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Container> */}
          <Footer/>
        </Container>
        <Dialog
          open={dialogData.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Typography align="center" variant="h4" className={classes.dialogTitle} >{dialogData.title}</Typography>
          <DialogContent className={classes.dialogBody}>
            <DialogContentText className={classes.dialogDesc}>
              {dialogData.desc}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
};

export default HomePage;
