import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, makeStyles, Grid, Divider, Box, Button
        } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Layout from '../layouts/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '../components';
import { FloatingMenu, InvsOutlook, InvsItem, InvsItemYears, AssetClasses, 
  GrpStructure, AssetAllocation, KeyIndv, Background } from '../components/company/';
import axios from 'axios';
import { CompanyProvider } from '../components/CompanyContext';
//import { createPopup } from '@typeform/embed';
//import "@typeform/embed/src/css/popup.scss";
import { typeformEmailAssets } from '../assets/scripts/typeformEmailAssets.js';
//import { ReactTypeformEmbed } from 'react-typeform-embed';
//import Results from './Results';
//https://auth0.com/blog/complete-guide-to-react-user-authentication/
//https://github.com/auth0-samples/auth0-express-api-samples/tree/master/01-Authorization-RS256



const useStyles = makeStyles((theme) => ({
  root: {
    /*overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 200px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 250px)',
    }*/
  },
  divider:{
    height:'5px'
  },
  header:{
    fontSize: theme.typography.pxToRem(25),
    marginTop:theme.spacing(5),
    marginBottom:theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(35),
    }
    

  },
  subHeader:{
    marginBottom:theme.spacing(3),
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(28),
    }
  },
  sections:{
    flexGrow: 1,
  },
  container:{
    maxWidth:'860px',
    [theme.breakpoints.up('lg')]: {
      maxWidth:'960px'
    },
    padding:theme.spacing(2,2,8,2)
  },
  table:{
    minWidth:'600px',
    marginBottom:theme.spacing(3),
    '& .MuiTableCell-sizeSmall':{
      padding:6
    },
    '& .dateCell':{minWidth:'90px'},
    '& .assetsCell':{minWidth:'150px'}
  },
  tableHeader:{
    borderBottom: 'solid 2px #aaa'
  },  
  section:{
    paddingTop: theme.spacing(2),
    paddingBottom:theme.spacing(4),
    '& img':{
      width: '100%'
    }
  },
  subTitle:{
    display:'flex',
    justifyContent:"space-between"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  chartContainer:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(3),
  },
  chart:{
    maxHeight:'450px'
  },
  noData:{
    height:'70vh',
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(3),
  }, 
}));



const GraphicsPage = () => {
  const ref = useRef(null);
  const classes = useStyles();
  const [request, setRequest] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setComData] = useState(undefined);
  const [redirectTo, setRedirect] = useState('');
  const { getAccessTokenSilently, user } = useAuth0();

  const assetsYears = [2020];

  //const invItems = ['Equities','Fixed Income','Real Estate']

  useEffect(() => {
    fetchData();
  }, [request]);

  useEffect(() => {
    getData();
  }, []);

  async function fetchData() {
    //console.log(request);
    if(request){
      try {
        setIsLoading(true);

        const token = await getAccessTokenSilently();
        //console.log(token);
        
        const response = await axios.get(request.url, {
          responseType:'stream', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        })

        //console.log(response);
        if(response.status === 200){
          if(request.funcName ==='setComData'){
            if(response.data.aum)
              setComData({...response.data,aum:response.data.aum.toFixed(2)});
            else
              setComData(response.data);
          }
        }
        setIsLoading(false);
        
      }catch (error) {
        //console.log(error);
      }
    }else{
      //console.log(request);
    }
    
  }

  function getData() {
    setRequest({
      method: 'GET',
      url: process.env.REACT_APP_API_URL+'/Institutions/GetDBInstitutionChart',
      //url: process.env.REACT_APP_API_URL+'/Institutions/GetInstitutionByInstitutionId/'+id,
      funcName: 'setComData'
    })
  }

  function handleKeyIndvClick(id){
    //console.log("https://form.typeform.com/to/Nodfw3g8?typeform-medium=embed-snippet#sfcid="+id+"&name="+user.nickname+"&email="+user.email)
    const tfPopup = window.typeformEmbed.makePopup(
      "https://form.typeform.com/to/Nodfw3g8?typeform-medium=embed-snippet#sfcid="+id+"&name="+user.nickname+"&email="+user.email,
      {
        mode: 'popup',
        autoClose: 3000,
        hideHeaders: true,
        hideFooters: true
      }
    );
    tfPopup.open();
    //createPopup('Nodfw3g8?typeform-medium=embed-snippet#sfcid='+id).open();
    //createPopup('Nodfw3g8').open();
  }

  //console.log(isLoading, data);
  if(redirectTo!==''){
    return <Redirect push to={redirectTo} />
  }else if(!isLoading && (!data)){
    return (
      <CompanyProvider value={{...data,classes:classes, setRedirectFunc:setRedirect, searchKeywords:''}}>
        <Layout searchBar={true} path={'results'}>
          <div className={classes.root}>
            <Container  className={classes.container} >
              <Grid alignItems="center" container justify="center" className={classes.noData}>
                <Typography align="center" variant="h4" >
                  Sorry, no data is available.
                </Typography>
              </Grid>
            </Container>
          </div>
        </Layout>
      </CompanyProvider>
    );
  }else{
    return (
      <CompanyProvider value={{...data,classes:classes, setRedirectFunc:setRedirect, searchKeywords:''}}>
        <Layout searchBar={true} path={'results'}>

          <div className={classes.root} >
            {(isLoading || !data)?
              <Loading/>
              :
              <React.Fragment>
                <Container className={classes.container} ref={ref}>
                  <Typography className={classes.header} variant="h2" >
                    Graphics
                  </Typography>
                  <Divider className={classes.divider}/>
                    {data.aggAumByCountryChartList.length>0 && 
                      <>
                        <AssetAllocation years={assetsYears} type={'bar'} list={data.aggAumByCountryChartList} data={data.aggAumByCountryChartListForHighChart}  title={'Aggregated AUM size by Country'} />
                        <Divider className={classes.divider}/>
                      </>
                    }
                    {data.aggAumByInstitutionTypeChartList.length>0 && 
                      <>
                        <AssetAllocation years={assetsYears} type={'bar'} list={data.aggAumByInstitutionTypeChartList} data={data.aggAumByInstitutionTypeChartListForHighChart} title={'Aggregated AUM size by Institution Type'} />
                        <Divider className={classes.divider}/>
                      </>
                    }
                    {data.assetclassesBreakdownByCountryChartList.length>0 && 
                      <>
                        <AssetAllocation years={assetsYears} type={'column'} list={data.assetclassesBreakdownByCountryChartList} data={data.assetclassesBreakdownByCountryChartListForHighChart} title={'Asset Class Breakdown By Country'} />
                        <Divider className={classes.divider}/>
                      </>
                    }
                    {data.assetclassesBreakdownByInstitutionTypeChartList.length>0 && 
                      <>
                        <AssetAllocation years={assetsYears} type={'column'} list={data.assetclassesBreakdownByInstitutionTypeChartList} data={data.assetclassesBreakdownByInstitutionTypeChartListForHighChart} title={'Asset Class Breakdown By Institution Type'} />
                        <Divider className={classes.divider}/>
                      </>
                    }
                </Container>
              </React.Fragment>
            }
          </div>
        </Layout>
      </CompanyProvider>
    );
  }
};

export default GraphicsPage;