import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";

const Highlight = () =>{
  const {classes} = useContext(CompanyContext);
  return (
    <Grid container spacing={2} className={classes.section} >
      <Grid item xs={12} className={classes.subTitle}>
        <Typography variant="subtitle1" gutterBottom>
          {'Highlight'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={5}>
        <img src="https://via.placeholder.com/600x400?text=Image"/>
      </Grid>
      <Grid item xs={12} md={6} lg={7}>
        <Typography variant="h2" gutterBottom>
          {'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {'Dec 11, 2020'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Highlight;