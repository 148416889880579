import React from 'react';
import { Typography, Container, makeStyles, Grid } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";
//import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root:{},
  title:{
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
  }
}));

const LoginPage = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <Typography className={classes.title} variant="h2" >
          Login
        </Typography>
        <Grid container>
          <button onClick={() => loginWithRedirect()}>Log In</button>
        </Grid>
      </Container>
    </div>
  );
};

export default LoginPage;
