import React, {useState, useEffect, useContext} from 'react';
import { CompanyContext } from "./CompanyContext";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Select, FormControl, InputLabel, MenuItem, Checkbox, 
          ListItemText, Collapse, ListItem, List, ListSubheader, FormHelperText} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({

  formControl: {
    width:'100%',
    padding: theme.spacing(1),
    '& .MuiInput-underline:after':{
      borderBottomColor:theme.palette.secondary.main
    },
    marginTop: 0,

  },
  label:{
    color: '#000',
    left: 'auto',
    fontWeight:'bold',
    fontSize:theme.typography.pxToRem(14),
    '&.Mui-focused':{color:theme.palette.secondary.main},
    '&.MuiInputLabel-shrink':{
      fontSize:theme.typography.pxToRem(16),
      color:theme.palette.secondary.dark
    },
    marginTop: 0,

  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  shortDropDown:{
    '& .MuiInput-formControl': {
      marginTop: '0'
    },
    padding:'2px'
  },
  selectText:{
    fontWeight:'bold',
    fontSize:theme.typography.pxToRem(14)
  },
  filter:{
    whiteSpace: "normal",
    '& .MuiCheckbox-root':{
      padding: '2px 10px 2px 2px'
    },
    '& .MuiSvgIcon-root':{
      color:'#777'
    },
    '&.Mui-selected':{
      '& .MuiSvgIcon-root':{
        color:theme.palette.primary.main
      }
    }
  },
  subFilter:{
    paddingLeft:theme.spacing(4)
  },
  filterText:{
    '& span':{
      fontSize:theme.typography.pxToRem(13)
    }
  },
  nested: {
    
  },
  expandFilter:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems: 'flex-start'
  },
  expandParent:{
    width:'100%', 
    display:'flex'
  },
  collapse:{
    width:'100%',
    paddingLeft: theme.spacing(1)
  },
  parent:{
    color: '#000',
    fontWeight:'bold',
    fontSize:theme.typography.pxToRem(15),
    lineHeight:'27px',
    backgroundColor:'#d2d2d2'
  },
  subHeader:{
    color: '#555',
    fontWeight:'bold',
    paddingLeft: theme.spacing(3),
    fontSize:theme.typography.pxToRem(14),
    lineHeight:'25px',
    backgroundColor:'#fff'
  },
  errorText:{
    color:'#f00'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
let MenuProps = {}, currParent = '';



function getStyles(item, selected, theme) {
  return {
    fontWeight:
      (item===selected && selected!=='')
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FilterCheckBox(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {assetError} = useContext(CompanyContext);
  const {items, filterName, selectedItem, multiple, expandable, label, width, hideSelected, id, convertItem} = props; 

  const [selectedItems, setSelected] = useState(selectedItem);
  const [expandedItems, setExpand] = useState([]);

  const dropLabel = filterName.replace(/\s+/g, '-').toLowerCase()+'-label';
  const dropId = filterName.replace(/\s+/g, '-').toLowerCase()+'-id';

  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: (width)?width:250,
      },
    }
  };

  const handleChange = (event) => {
   
    //console.log(event.target.value)
    if(event.target && event.target.value!==selectedItems){
      setSelected(event.target.value);
      props.onSelect({name:filterName, value:event.target.value});
    }
  };

  useEffect(()=>{
    setSelected(selectedItem)
  },[selectedItem]);



  const handleExpandClick =(item)=>{
    //console.log(item);
    let _items = [...expandedItems];
    let index = expandedItems.indexOf(item);
    if(index>-1){
      _items.splice(index,1);
    }else
      _items.push(item);

    setExpand(_items);
  }
  const handleClickItem = (value)=>{
    let _items = [...selectedItems];
    let index = selectedItems.indexOf(value);
    if(index>-1){
      _items.splice(index,1);
    }else
      _items.push(value);

    setSelected(_items);
  }
  const isOpen = (item)=>{
    return (expandedItems.indexOf(item)>-1);
  }

  const renderExpandable = (items)=>{
    return(
      items.map((item,i) => (
        <div>
          <ListItem onClick={()=>handleExpandClick(item)} className={classes.expandFilter} key={'listitem'+item.key+i} value={item.key} style={getStyles(item.key, selectedItems, theme)}>
            <div className={classes.expandParent}>
              <ListItemText primary={item.key} className={classes.filterText}/>
              {isOpen(item) ? <ExpandLess /> : <ExpandMore />}
            </div>
          </ListItem>
          <Collapse className={classes.collapse} key={'collapse'+item.key+i} in={isOpen(item)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                item.children.map((child,j)=>{
                  return(
                    <ListItem key={'listitem'+child.key+j} button onClick={()=>handleClickItem(child.key)} className={clsx([classes.filter])}  value={child.key+j} style={getStyles(child.key, selectedItems, theme)}>
                      <Checkbox size={'small'} checked={selectedItems.indexOf(child.key) > -1} />
                      <ListItemText primary={child.key} className={classes.filterText}/>
                    </ListItem>
                  )
                })
              }
            </List>
          </Collapse>
        </div>
      ))
    )
  }

  const renderSelectGroup = (item,i) => {
    let menuItem = [], subHeader, parent, childItems = [];

    if(item.children){
      //console.log(i, item.parent, currParent)
      if(item.parent !== currParent || i===0){
        currParent = item.parent;
        menuItem = [<ListSubheader key={`parent `+item.parent} className={classes.parent}>{item.parent}</ListSubheader>];
      }
      item.children.forEach((c,i)=>{
        childItems.push(
          <MenuItem className={clsx([classes.filter, classes.subFilter])} key={c.key+i} value={c.key} style={getStyles(c.key, selectedItems, theme)}>
            <Checkbox size={'small'} checked={selectedItems.indexOf(c.key) > -1} />
            <ListItemText primary={c.text} className={classes.filterText}/>
          </MenuItem>
        );
      });
      //console.log(menuItem);
      return [...menuItem, <ListSubheader key={`subheader `+item.parent} className={classes.subHeader}>{item.text}</ListSubheader>,...childItems];
    }else{
      menuItem = <MenuItem className={classes.filter} key={items[i].key+i} value={items[i].key} style={getStyles(items[i].key, selectedItems, theme)}>
        <Checkbox size={'small'} checked={selectedItems.indexOf(items[i].key) > -1} />
        {items[i].text?
          <ListItemText primary={items[i].text} className={classes.filterText}/>
          :<ListItemText primary={items[i].key} className={classes.filterText}/>
        }
      </MenuItem>;

      return [<ListSubheader className={classes.parent}>{item.parent}</ListSubheader>,menuItem];
    }
    
  };
  const getItemText = (keys) =>{
    //console.log(keys,items)
    let newKeys = [];
    keys.forEach(key=>{
      let found = items.find(o=>{
        return (o.key===key)
      })
      if(found)
        newKeys.push(found.text);
    })
    //console.log(newKeys);
    return newKeys.join(', ');
  }
  //console.log(filterName,items);
  //console.log(label)
  //console.log(selectedItems);

  if(convertItem){
    return (
      <div key={filterName}>
        {items && (items.length>0) &&
          <FormControl className={clsx(classes.formControl,!label && classes.shortDropDown)}>
            <InputLabel id={dropLabel} className={classes.label}>{filterName}</InputLabel>
            <Select
              labelId={dropLabel}
              id={dropId}
              multiple={props.multiple||false}
              value={selectedItems}
              renderValue={(selected) => getItemText(selected)}
              onChange={handleChange}
              MenuProps={MenuProps}
              className={classes.selectText}
            > 
              {items.map((item, i) => {
                if(item.parent && label){
                  return renderSelectGroup(item, i);
                }else{
                  return <MenuItem className={classes.filter} key={item.key+i} value={item.key} style={getStyles(item.key, selectedItems, theme)}>
                    <Checkbox size={'small'} checked={selectedItems?.indexOf(item.key) > -1} />
                    <ListItemText primary={item.text||item.key} className={classes.filterText}/>
                  </MenuItem>
                }
              })}
            </Select>
            {assetError && assetError[id] &&
              <FormHelperText className={classes.errorText}>{assetError[id]}</FormHelperText>
            }
          </FormControl>
        }
      </div>
    );
  }else{
    return (
      <div key={filterName}>
        {items && (items.length>0) &&
          <FormControl className={clsx(classes.formControl,!label && classes.shortDropDown)}>
            <InputLabel id={dropLabel} className={classes.label}>{filterName}</InputLabel>
            <Select
              labelId={dropLabel}
              id={dropId}
              multiple={props.multiple||false}
              value={selectedItems}
              renderValue={(selected) => (hideSelected)?'':Array.isArray(selected)?selected.join(', '):selected}
              onChange={handleChange}
              MenuProps={MenuProps}
              className={classes.selectText}
            > 
              {items.map((item, i) => {
                if(item.parent && label){
                  return renderSelectGroup(item, i);
                }else{
                  return <MenuItem className={classes.filter} key={item.key+i} value={item.key} style={getStyles(item.key, selectedItems, theme)}>
                    <Checkbox size={'small'} checked={selectedItems?.indexOf(item.key) > -1} />
                    <ListItemText primary={item.text||item.key} className={classes.filterText}/>
                  </MenuItem>
                }
              })}
            </Select>
            {assetError && assetError[id] &&
              <FormHelperText className={classes.errorText}>{assetError[id]}</FormHelperText>
            }
          </FormControl>
        }
      </div>
    );
  }
  
}
