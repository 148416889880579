import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, makeStyles, Grid, Divider, Dialog, DialogActions, DialogContent, DialogContentText, Box, Link, Chip, Button
        } from '@material-ui/core';
import { Redirect,useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Layout from '../layouts/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '../components';
import { FloatingMenu, InvsOutlook, InvsItem, InvsItemYears, AssetClasses, 
  GrpStructure, AssetAllocation, KeyIndv, Background } from '../components/company/';
import axios from 'axios';
import { CompanyProvider } from '../components/CompanyContext';
//import { createPopup } from '@typeform/embed';
//import "@typeform/embed/src/css/popup.scss";
import { typeformEmailAssets } from '../assets/scripts/typeformEmailAssets.js';
import { transform } from 'lodash';
//import { ReactTypeformEmbed } from 'react-typeform-embed';
//import Results from './Results';
//https://auth0.com/blog/complete-guide-to-react-user-authentication/
//https://github.com/auth0-samples/auth0-express-api-samples/tree/master/01-Authorization-RS256



const useStyles = makeStyles((theme) => ({
  root: {
    /*overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 200px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 250px)',
    }*/
  },
  divider:{
    height:'5px'
  },
  header:{
    fontSize: theme.typography.pxToRem(25),
    marginTop:theme.spacing(5),
    marginBottom:theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(35),
    }
    

  },
  subHeader:{
    marginBottom:theme.spacing(3),
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(28),
    }
  },
  sections:{
    flexGrow: 1,
  },
  container:{
    maxWidth:'860px',
    [theme.breakpoints.up('lg')]: {
      maxWidth:'960px'
    },
    padding:theme.spacing(2,2,8,2)
  },
  table:{
    minWidth:'600px',
    marginBottom:theme.spacing(3),
    '& .MuiTableCell-sizeSmall':{
      padding:6
    },
    '& .dateCell':{minWidth:'90px'},
    '& .assetsCell':{minWidth:'150px'}
  },
  tableHeader:{
    borderBottom: 'solid 2px #aaa'
  },  
  section:{
    paddingTop: theme.spacing(2),
    paddingBottom:theme.spacing(4),
    '& img':{
      width: '100%'
    }
  },
  subTitle:{
    display:'flex',
    justifyContent:"space-between"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accord:{
    width:'100%',
    '&:hover .MuiPaper-root':{
      backgroundColor:'#f7f7f7'
    }
  },
  accordWrapper:{
    boxShadow: 'none'
  },
  accordBody:{
    flexDirection:'column'
  },
  accordTable:{
    marginTop:'-20px',
    marginBottom:theme.spacing(1),
  },
  accordTableBody:{
    minWidth:'600px',
    margin: '0 auto',
    marginBottom:theme.spacing(1),
    '& .MuiTableCell-sizeSmall':{
      padding:6
    },
    '& .dateCell':{minWidth:'120px'}
  },
  chartContainer:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(3),
  },
  chart:{
    maxHeight:'450px'
  },
  listContainer:{
    padding:theme.spacing(2)
  },
  listSubHeader:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
    '& a':{
      color:theme.palette.secondary.dark,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold'
    }
  },
  listItem:{
    '& a':{
      color:theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(14)
    }
  },
  floatingMenu:{
    position:'fixed', 
    
    right:'50px', 
    display:'none',
    [theme.breakpoints.up('lg')]: {
      display:'block',
      maxWidth:'250px',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth:'150px',
      '& a':{
        fontSize: theme.typography.pxToRem(14)
      }
    },
    backgroundColor:'#f7f7f7',
    zIndex:1000,
    padding:theme.spacing(1),
    '& a':{
      borderBottom:'solid 1px #dedede'
    }
  },
  menuItem:{
    width:'100%',
    display: 'block'
  },
  menuLogo:{
    '& img':{
      width:'100%',
    },
    marginBottom:theme.spacing(2),
  },
  noData:{
    height:'70vh',
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(3),
  }, 
  select:{
    width:'120px',
    '& label':{
      color:'#777777',
      fontSize: theme.typography.pxToRem(14)
    },
    '& .MuiInput-input':{
      fontSize: theme.typography.pxToRem(14),
      paddingLeft:theme.spacing(1)
    }
  }, 
  compareTables:{
    '& table':{
      minWidth:'0'
    }
  }, 
  yearSelector:{
    width:'125px'
  },
  yearHeader:{
    width:'150px'
  },
  clickableRow:{
    '&:hover':{
      backgroundColor:'#ececec',
      cursor:'pointer'
    }
  },
  dialogMainWrap: {
    padding: '20px 15px',
    backgroundColor: '#ffd4c4',
  },
  
  dialogTitle: {
    color: '#ED1C24',
    fontWeight: '600',
    textAlign: 'left',
    fontSize:theme.typography.pxToRem(24),
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },

  dialogDesc: {
    color: '#030303',
    textAlign: 'left',
    lineHeight: '1.5',
    margin: '15px 0 20px 0',
    fontSize:theme.typography.pxToRem(18),
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },

  dialogButton: {
    color: '#fff',
    fontWeight: 600,
    borderRadius: '4px',
    padding: '10px 15px',
    display: 'inline-block',
    textTransform: 'uppercase',
    backgroundColor: '#ED1C24',
    fontSize:theme.typography.pxToRem(18),
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',

    '&:last-of-type': {
      margin: '0 0 0 15px'
    }
  },

  blurSection: {
    filter: 'blur(6px)',
    pointerEvents: 'none'
  }
}));

const CompanyPage = () => {
  let { id } = useParams();
  const ref = useRef(null);
  let history = useHistory();
  const classes = useStyles();
  const [request, setRequest] = useState(undefined);
  const [isLoaded, setIsLoading] = useState(true);
  const [data, setComData] = useState(undefined);
  const [redirectTo, setRedirect] = useState('');
  const { isAuthenticated, user, error, getAccessTokenSilently,loginWithRedirect} = useAuth0();
  const [dialogData, setDialog] = useState({
    title: '',
    desc: '',
    'open': false
  });
  const assetsYears = [2020];
  //const invItems = ['Equities','Fixed Income','Real Estate']
  const handleClose = () => {
    setDialog({...dialogData,open:false});
  };

  const handlelogin = () => {
    const pathName = history.location.pathname;
    localStorage.setItem('PageUrl', pathName);
    loginWithRedirect();
  };

  useEffect(()=>{
    localStorage.removeItem('PageUrl');
    if(!isAuthenticated && !user){
      setDialog({
        open:true,
        //title:decodeURIComponent(params.error_description), 
        title:"Become an ASSET OWNER INSIGHTS Member",
        desc:'Following information for subscription members only. Become a member for unlimited access to all Asset Owner Insights content.'
      })
    }else if(isAuthenticated && user){
      setDialog({
        title: '',
        desc: '',
        'open': false
      });
    }
  },[isAuthenticated,user]);
  let menuData = [
                  {name:"Top",id:"top",status:'show'},
                  {name:"Investment outlook",id:"outlook",status:'show'},
                  {name:"Data point",id:"data",status:'show'},
                  {name:"Asset Allocation",id:"asset",status:'show'},
                  {name:"Group Structure",id:"group",status:'show'},
                  {name:"Key Individual",id:"keyIndv",status:'show'},
                  {name:"Background",id:"background",status:'show'}];

  const [menuItems, setMenuData]= useState(menuData);


  useEffect(() => {
   fetchData();
   }, [request]);

  useEffect(() => {
    getData();
  }, [id]);

  const checkMenu = (data) =>{
    let _menu = [...menuItems];
    if(data.institutionsInvestmentOutlookList.length===0)
      _menu[1].status = 'hide';
    if(data.institutionsInvestmentPortfolioList.length===0)
      _menu[2].status = 'hide';
    if(data.assetClassChartList.items.length===0)
      _menu[3].status = 'hide';
    if(data.subsidiaries.length===0)
      _menu[4].status = 'hide';
    if(data.institutionsContactsList.length===0)
      _menu[5].status = 'hide';

    setMenuData(_menu);
  }
  async function fetchData() {
    //console.log(request);
    if(request){
        try {
        setIsLoading(true);

         let response ;

        if(isAuthenticated && user)
        {
          const token = await getAccessTokenSilently();
          response = await axios.get(request.url, {
            responseType:'stream', 
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
          });
        }
        else{
          response = await axios.get(request.url, {
            responseType:'stream', 
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
          });
        }
       

        //console.log(response);
        if(response.status === 200){
          if(request.funcName ==='setComData'){
            //console.log(response.data);
            checkMenu(response.data);
            if(response.data.aum)
              setComData({...response.data,aum:response.data.aum.toFixed(2)});
            else
              setComData(response.data);
          }
          else if(request.funcName ==='setinsitutionId')
          {
            console.log('id',response.data);
            setRedirect('/institution/'+response.data.pkInstitutionId);
           // setinsitutionId(response.data.pkInstitutionId);         
           }
        }
        setIsLoading(false);
        
      }catch (error) {
        //console.log(error);
      }
    }else{
      //console.log(request);
    }
    
  }



  function getData() {
    if(!isNaN(+id))
    {
      setRequest({
        method: 'GET',
        url: process.env.REACT_APP_API_URL+'/Institutions/GetDBInstitutionById/'+id,
        //url: process.env.REACT_APP_API_URL+'/Institutions/GetInstitutionByInstitutionId/'+id,
        funcName: 'setComData'
      })
     
    }
    else{
      setRequest({
        method: 'GET',
        url: process.env.REACT_APP_API_URL+'/Institutions/GetDBInstitutionBySFId/'+id,
        //url: process.env.REACT_APP_API_URL+'/Institutions/GetInstitutionByInstitutionId/'+id,
        funcName: 'setinsitutionId'
      })
    }
  
   
  
  }
  const handleYear = (date)=>{
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let insitutionDate= new Date(date);
    let insitutionYear = insitutionDate.getFullYear();
    const thirtyDaysInMs = 90 * 24 * 60 * 60 * 1000;
    const timeDiffInMs = currentDate.getTime() - insitutionDate.getTime();

if(timeDiffInMs >= thirtyDaysInMs){
  return false;
}else{
  return true;
}
  }
  function handleKeyIndvClick(id, individualName, jobTitle){
    //console.log("https://form.typeform.com/to/Nodfw3g8?typeform-medium=embed-snippet#sfcid="+id+"&name="+user.nickname+"&email="+user.email)
    const tfPopup = window.typeformEmbed.makePopup(
      "https://form.typeform.com/to/Nodfw3g8?typeform-medium=embed-snippet#sfcid="+id+"&name="+user.nickname+"&email="+user.email+"&ki_name="+individualName+"&ki_jobtitle="+jobTitle+"&ki_institution="+data.name,
      {
        mode: 'popup',
        autoClose: 3000,
        hideHeaders: true,
        hideFooters: true
      }
    );
    tfPopup.open();
    //createPopup('Nodfw3g8?typeform-medium=embed-snippet#sfcid='+id).open();
    //createPopup('Nodfw3g8').open();
  }

  if(redirectTo!==''){
    return <Redirect push to={redirectTo} />
  }else if(!isLoaded && (!data)){
    return (
      <CompanyProvider value={{...data,menuItems:menuItems,classes:classes, setRedirectFunc:setRedirect, searchKeywords:''}}>
        <Layout searchBar={true} path={'results'}>
          <div className={classes.root}>
            <Container  className={classes.container} >
              <Grid alignItems="center" container justify="center" className={classes.noData}>
                <Typography align="center" variant="h4" >
                  Sorry, no data is available.
                </Typography>
              </Grid>
            </Container>
          </div>
        </Layout>
      </CompanyProvider>
    );
  }else{
    return (
      <CompanyProvider value={{...data,menuItems:menuItems,classes:classes, setRedirectFunc:setRedirect, searchKeywords:''}}>
        <Layout searchBar={true} path={'results'}>

          <div className={classes.root} >
            {(isLoaded || !data)?
              <Loading/>
              :
              <React.Fragment>
                <FloatingMenu classes={classes}/>
                <Container className={classes.container} ref={ref}>
                  <Typography id={menuItems[0].id} className={classes.header} variant="h2" >
                    {data.name}  {handleYear(data.dataAsOfDate) == true ?   <Chip className={classes.chip}  size="small" color="primary" label="New" />: ""}
                  </Typography>
                
                  <Typography className={classes.subHeader} variant="h3" >
                    Asset Under Management: {data.fkCurrencyCode} {data.aum ? data.aum : '-'} billion
                  </Typography>
                  
                  <Divider className={classes.divider}/>
                  <div className={classes.sections}>
                    {/* Investment outlook in next 6-12 months */}
                    {data.institutionsInvestmentOutlookList.length>0 && 
                      <>
                        <InvsOutlook id={menuItems[1].id} isShow={dialogData.open ? true:false }/>
                        <Divider className={classes.divider}/>
                      </>
                    }
                    {/* Data point of each asset classes */}
                    {data.institutionsInvestmentPortfolioList.length>0 && !dialogData.open && 
                      <>
                        <AssetClasses id={menuItems[2].id}/>
                        <Divider className={classes.divider}/>
                      </>
                    }
                    {dialogData.open &&
                      <Box className={classes.dialogMainWrap}>
                        <Typography align="center" variant="h4" className={classes.dialogTitle}>
                          {dialogData.title}
                        </Typography>
                        <Box className={classes.dialogDesc}>
                          {dialogData.desc}
                        </Box>
                        <Box>
                          <Link onClick={() => handlelogin()} className={classes.dialogButton}>
                            Log In
                          </Link>                    
                          <Link href='https://asianinvestor.net/account/subscribe' target="blank" className={classes.dialogButton}>
                            Subscribe
                          </Link>
                        </Box>
                      </Box>
                    }
                    {/* Accordion */}
                      {(data.institutionAssetClassList && data.institutionAssetClassList.length>0) &&
                      <Box className={dialogData.open ? classes.blurSection : ''}>
                          <Grid container spacing={2} className={classes.section}>
                            {data.institutionAssetClassList && data.institutionAssetClassList.map((item, i) => (
                              <InvsItem key={item.name+i} isShow={dialogData.open ? true : false} item={item} />
                            ))}
                          </Grid>
                          <Divider className={classes.divider}/>
                        </Box>
                      }
                    {data.assetClassChartList.items.length>0 && !dialogData.open && 
                     <Box className={dialogData.open ? classes.blurSection : ''}>
                        <AssetAllocation id={menuItems[3].id} type={'chart'} data={data.assetClassChartList.items} title={'Asset Allocation'} />
                        <Divider className={classes.divider}/>
                      </Box>
                    }
                    {data.subsidiaries.length>0 && 
                      <Box className={dialogData.open ? classes.blurSection : ''}>
                        <GrpStructure id={menuItems[4].id} />
                        <Divider className={classes.divider}/>
                      </Box>
                    }
                    {data.institutionsContactsList.length>0 && 
                      <Box className={dialogData.open ? classes.blurSection : ''}>
                        <KeyIndv id={menuItems[5].id} clickItem={handleKeyIndvClick}/>
                        <Divider className={classes.divider}/>
                      </Box>
                    }
                     <Box className={dialogData.open ? classes.blurSection : ''}>
                    <Background id={menuItems[6].id}  classes={classes}/>
                    <Divider className={classes.divider}/>
                    </Box>
                    
                  </div>
                </Container>
              </React.Fragment>
            }
          </div>
        </Layout>
      </CompanyProvider>
    );
  }
};

export default CompanyPage;
