import React, { useContext } from 'react';
import { Grid, Typography, List, ListSubheader, ListItem, ListItemText, Link } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";

const GrpStructure = (props) =>{
  const {classes, subsidiaries: rows} = useContext(CompanyContext);

  //console.log(rows);
  return (
    <Grid id={props.id} container spacing={2} className={classes.section}>
      <Grid item xs={12} className={classes.subTitle}>
        <Typography variant="subtitle1" gutterBottom>
          {'Group Structure'}
        </Typography>
      </Grid>
      {(rows.length==0)?
      <Grid item xs={12}>
        <Typography gutterBottom>
          {'No Information is available'}
        </Typography>
      </Grid>
      :
      <Grid item xs={12} >
        <List className={classes.listContainer} subheader={<li />}>
          <li className={classes.listSubHeader}>
            <Link href={'/'+rows[0].url}>{rows[0].name}</Link>
          </li>
          <li className={classes.listSection}>
            <ul className={classes.subList}>
              {rows[0].child && 
                rows[0].child.map((item) => (
                  <ListItem key={item.name} className={classes.listItem}>
                    - <Link href={'/'+item.url}>{item.name}</Link>
                  </ListItem>
                ))
              }
            </ul>
          </li>
        </List>
      </Grid>
    }
    </Grid>
  );
  
}

export default GrpStructure;