import React, { useContext, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Moment from 'react-moment';

const AssetAllocation = (props) =>{
  const {classes} = useContext(CompanyContext);
  const {title, data: rows, list, type, years} = props;
  let chartData= [], chartOptions, curr='', date;
  const colors = [
        '#f95d6a',
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#FFD9DA',
        '#30343F',
        '#1B2021',
        '#C7D9B7',
        '#ffa600',
        '#B8B3E9',
        '#ff7c43',
        '#D999B9',
        '#D17B88',
        '#717568'];

  if(type==='chart'){
    rows.forEach((o,i)=>{
      if(i===0)
        date = o.year;
      chartData.push({
        name:o.assetClassName,
        y:parseFloat(o.value)
      });
    })
    //console.log(chartData);
    chartOptions = {

      credits: {
        enabled: false
      },
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
        text: null,
        style: {
          display: 'none'
        }
      },
      colors:colors,
      tooltip: {
        formatter: function() {
          return this.key+': '+this.y + '%';
        }
      },
      series: [{
        showInLegend: false,
        dataLabels:{
          enabled: true,
          format: '{key}<br/>{y} %'
        },
        name: "",
        data: chartData
      }]
    }
  }else if(type==='bar'){

    rows.yaxis.forEach((o,i)=>{
      if(years.indexOf(o.name)>-1){
        chartData.push({
          name:o.name,
          data:o.data.map(d=>parseFloat(d.toFixed(2)))
        });
      }
    })

    /*rows.yaxis.forEach((o,i)=>{
      chartData.push({
        name:o.name,
        data:o.data.map(d=>parseFloat(d.toFixed(2))),
        stack: o.stack
      });
    })*/
    //chartData = [...rows.yaxis];

    //console.log(chartData);

    chartOptions = {

      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'bar'
      },
      title: {
        text: null,
        style: {
          display: 'none'
        }
      },
      colors:colors,
      xAxis: {
        categories: rows.xaxis,
        title: {
            text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Billions',
          align: 'high'
        },
        
      },
      tooltip: {
        valueSuffix: ' billions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y > 0) {
                return this.y;
              }
            }
          }
        }
      },
      legend: {
        enabled: true
      },
      /*legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          shadow: true
      },*/
      credits: {
          enabled: false
      },
      series: chartData
    }
  }else if(type==='column'){
    let colorIndex = 0;
    rows.yaxis.forEach((o,i)=>{
      if(years.indexOf(o.stack)>-1){
        let data = {
          name:o.name,
          id:o.name,
          data:o.data.map(d=>parseFloat(d.toFixed(2))),
          stack: o.stack
        }
        if(curr===''||curr!==o.name){
          curr = o.name;
          colorIndex++;
          data = {...data, color:colors[colorIndex]};
        }else{
          data = {...data, linkedTo:o.name, color:colors[colorIndex]};
        }
        chartData.push(data);
      }
      
    })
    //console.log(chartData);
    //chartData = [...rows.yaxis];

    chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column'
      },
      title: {
        text: null,
        style: {
          display: 'none'
        }
      },
      colors:colors,
      xAxis: {
        categories: rows.xaxis,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: true,
          crop: false,
          overflow: 'none',
          formatter: function() {
            return this.stack;
          },
          style: {
            fontSize: '10px',
          },
          useHTML:true
        }
          
      },
      tooltip: {
        valueSuffix: ' Billions',
        formatter: function() {
          //console.log(this);
          return 'Total: '+this.total+'<br/>Year: '+this.series.userOptions.stack+' <br/>'+this.series.userOptions.name+' <br/>'+this.y+' Billions';
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointPadding: 0,
          groupPadding: 0,
          dataLabels: {
              enabled: false
          }
        }
      },
      legend: {
        enabled: true
      },
      series: chartData
    }
  }
  
  //console.log(chartOptions,rows);
  const options = {
    responsive: true,
    maintainAspectRatio: false, 
  };
  //console.log(rows);
  return (
    <Grid id={props.id} container spacing={2} className={classes.section}>
      <Grid item xs={12} className={classes.subTitle}>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {/*'Last update at:'} {'Dec 11, 2020'*/}
        </Typography>
      </Grid>
      {(chartData.length==0)?
      <Grid item xs={12}>
        <Typography gutterBottom>
          {'No Information is available'}
        </Typography>
      </Grid>
      :
      <Grid container spacing={3}  justify="center" alignItems="center" className={classes.chartContainer}>
        <Grid item xs={12} md={10}>
          <div className={classes.chart}>
            {/*<Pie height={300} data={chartData} options={options}  />*/}
            <HighchartsReact 
              highcharts={Highcharts} 
              options={chartOptions} 
            />
            {date && 
              
                <Typography gutterBottom align={'center'} variant={'caption'}>
                  <Box mt={2}>
                    Data as of date <Moment format="YYYY-MM-DD">{date}</Moment>
                  </Box>
                </Typography>
              
            }
          </div>
        </Grid>
      </Grid>
    }
    </Grid>
  );
}

export default AssetAllocation;