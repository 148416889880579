import React, { useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";

const KeyIndv = (props) =>{
  const {classes, institutionsContactsList: rows} = useContext(CompanyContext);

  return (
    <Grid id={props.id} container spacing={2} className={classes.section}>
      <Grid item xs={12} className={classes.subTitle}>
        <Typography variant="subtitle1" gutterBottom>
          {'Key Individual'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        { rows && (rows.length>0) ? (
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Job Title</TableCell>
                  <TableCell align="right">Office</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow  key={row.firstName+row.lastName+i} >
                    <TableCell component="th" scope="row">
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell align="left">{row.jobTitle}</TableCell>
                    <TableCell align="right">{row.countryName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ):(
          <Typography gutterBottom>
            {'No Information is available'}
          </Typography>
        )}

      </Grid>
    </Grid>
  );
}

export default KeyIndv;