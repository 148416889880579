import React , { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"

import Analytics from "./Analytics"
 
export default function useGoogleAnalytics(history) {
  const location = useLocation();
  //const history = useHistory();
  useEffect(() => {
    Analytics.init();
  }, [])
 
  useEffect(() => {
    const currentPath = location.pathname + location.search;
    //console.log(location);
    Analytics.sendPageview(currentPath);
  }, [location])

    

  useEffect(() => {
    history.listen((location) => {
      //console.log(location.pathname);
      Analytics.setPage(location.pathname);
      Analytics.sendPageview(location.pathname + location.search)
      //Analytics.sendPageview(location.pathname);
    });
  },[]);


}