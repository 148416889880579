import React, { useState, useEffect } from 'react';
import { Typography, Container, makeStyles, Grid, List, ListItem, ListItemText, Link
  } from '@material-ui/core';
import { Redirect, Link as RLink } from 'react-router-dom';
import { useAsyncHook, Loading} from '../components';
import Layout from '../layouts/Layout';
import SearchBar from '../layouts/SearchBar';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { CompanyProvider } from '../components/CompanyContext';
//import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3)
    /*overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 200px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 250px)',
    }*/
  },
  title:{
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(2),
    '& a':{
      color:'#000000'
    },
    '& a:hover':{
      color:theme.palette.primary.main
    }
  },
  searchBar:{
    justifyContent:'center',
    marginTop: '20vh',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginTop: '8vh',
      marginBottom: theme.spacing(6),
    }
  }, 
  listContainer:{
    maxWidth:'700px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  listItem:{
    paddingLeft:0,
    paddingTop:0
  }
}));

const SearchPage = () => {
  let history = useHistory();
  const { isAuthenticated, user, loading } = useAuth0();
  const [instructReq, setInstructReq] = useState(undefined);
  const [instructData, loadingInstructData] = useAsyncHook(instructReq);
  const [redirectTo, setRedirect] = useState('');

  const classes = useStyles();
  useEffect(() => {
    const fn = async () => {
      if (!loading) {
        //console.log("handleRedirectCallback: " + loading);
        //await handleRedirectCallback();
      }else{
        //console.log(isAuthenticated, user);
      }
    };
    fn();
  }, [isAuthenticated, loading]);

  useEffect(()=>{
    setInstructReq({
      method: 'GET',
      authorized: true,
      url: process.env.REACT_APP_API_URL+'/MainPage/GetMainPage'
    });
  },[]);


  //console.log(instructData);
  if(redirectTo!==''){
    return <Redirect push to={redirectTo} />
  }else{
    return (
      <CompanyProvider value={{setRedirectFunc:setRedirect, searchKeywords:'' }}>
        <Layout searchBar={false} path={'results'}>
          <div className={classes.root}>
            <Container maxWidth={'lg'}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid container className={classes.searchBar} >
                  <Grid item xs={12} md={8} lg={6}>
                    <SearchBar color={'white'} searchKeywords={''} path={'results'} submitSearch={setRedirect} />
                  </Grid>
                </Grid>
                { (loadingInstructData || instructData.length==0) ? 
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Loading/>
                  </Grid>
                :
                  <Grid container className={classes.listContainer}
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={2}
                   >
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h5" className={classes.title}>
                        Location
                      </Typography>
                      <List dense={true}>
                        {instructData.countryList.map((item) => (
                          <ListItem key={item.name} className={classes.listItem} button component={RLink} to={'/results?country='+encodeURIComponent(`${item.name}`)}>
                            <ListItemText
                              primary={item.name}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid> 
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h5" className={classes.title}>
                        Institution Type
                      </Typography>
                      <List dense={true}>
                        {instructData.institutionTypeList.map((item) => (
                          <ListItem key={item.name} className={classes.listItem} button component={RLink} to={'/results?typeOfInstitution='+encodeURIComponent(`${item.name}`)}>
                            <ListItemText
                              primary={item.name}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <Typography variant="h5" className={classes.title}>
                       Themes
                      </Typography>
                      <List dense={true}>
                        {instructData.assetClassList.map((item) => (
                          <ListItem key={item.name} className={classes.listItem} button component={RLink} to={'/insight?sort=institutionIODateTime&sortBy=DESC&AssetClass='+encodeURIComponent(`${item.name}`)}>
                            <ListItemText
                              primary={item.name}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    {/*
                    <Grid item xs={12} sm={2} >
                      <Typography variant="h5" className={classes.title}>
                        <RLink to="/graphics">Graphics</RLink>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                      <Typography variant="h5" className={classes.title}>
                        Countries
                      </Typography>
                      <List dense={true}>
                        {instructData.countryList.map((item) => (
                          <ListItem key={item.name} className={classes.listItem} button component="a">
                            <ListItemText
                              primary={item.name}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>*/}
                    
                  </Grid>
                }
              </Grid>
            </Container>
          </div>
        </Layout>
      </CompanyProvider>
    );
  }
};

export default SearchPage;
