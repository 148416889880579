import React from 'react';
import { Container, Grid, makeStyles, Typography, Box, Link } from '@material-ui/core';
import { Link as RLink } from 'react-router-dom';
import {LinkedIn, Twitter, Facebook} from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import HaymarketLogo from '../assets/images/haymarket.svg';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:'#000000',
    color:'#ffffff',
    padding: theme.spacing(2, 0),
    display: 'flex'
  },
  customContainer: {
    maxWidth: '1170px'
  },
  leftSide:{
    flexGrow:2,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    }
  },
  leftWrapper:{
    justifyContent:'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent:'center'
    }
  },
  rightSide:{
    flexGrow:1,
    marginBottom: theme.spacing(1)
  },
  rightWrapper:{
    justifyContent:'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent:'center'
    }
  },
  text:{
    fontSize:theme.typography.pxToRem(12),
  }, 
  hayLogo:{
    maxWidth:'160px',
    marginRight: '15px'
  },
  paragraphText: {
    fontSize:theme.typography.pxToRem(17),

    '& a': {
      color: '#fff',
      fontWeight: '600'
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { isAuthenticated, user } = useAuth0();
  const preventDefault = (e)=>{
    e.preventDefault();
  }
  const handleContactClick = (id)=>{
    const tfPopup = window.typeformEmbed.makePopup(
      "https://form.typeform.com/to/zjYrgODY?typeform-medium=embed-snippet",
      {
        mode: 'popup',
        autoClose: 3000,
        hideHeaders: true,
        hideFooters: true
      }
    );
    tfPopup.open();
    //createPopup('Nodfw3g8?typeform-medium=embed-snippet#sfcid='+id).open();
    //createPopup('Nodfw3g8').open();
  }
  //console.log(isAuthenticated,user);
  return (
    <div className={classes.root}>
      <Container className={classes.customContainer}>
        {/* <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.leftSide} >
            <Grid className={classes.leftWrapper}  container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Link target="_blank" rel="noopener" className={classes.social} href="https://www.linkedin.com/company/asian-investor" >
                  <LinkedIn/>
                </Link>
              </Grid>
              <Grid item>
                <Link target="_blank" rel="noopener" className={classes.social} href="https://twitter.com/AsianInvestor" >
                  <Twitter/>
                </Link>
              </Grid>
              <Grid item>
                <Link target="_blank" rel="noopener" className={classes.social} href="https://www.facebook.com/AsianInvestor" >
                  <Facebook/>
                </Link>
              </Grid>
              <Grid item>
                <Typography variant='caption' gutterBottom>Follow us on social media</Typography>
              </Grid>
            </Grid>
            <Grid className={classes.leftWrapper} container direction="row" spacing={2}>
            {(isAuthenticated && user) &&
              <Grid item>
                <RLink className={classes.text} to={'/account'}>
                  Manage account
                </RLink>
              </Grid>
            }
            
              <Grid item>
                <RLink className={classes.text} to="/terms">
                  Terms & Conditions
                </RLink>
              </Grid>
              <Grid item>
                <Link className={classes.text} href="#" onClick={handleContactClick}>
                  Contact us
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.rightSide}>
            <Grid className={classes.rightWrapper}  container spacing={2} alignItems="center" >
              <Grid item>
                <Link href="https://haymarket.com" target="_blank" rel="noopener">
                  <img src={HaymarketLogo} className={classes.hayLogo}/>
                </Link>
              </Grid>
              <Grid item>
                <Typography align="center" className={classes.text}>&copy; <Moment format="YYYY"/> Haymarket Media LImited. All rights reserved.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <Box mb={3}>
              <Typography className={classes.paragraphText}>
                Get detailed research report, get in touch with us today:
              </Typography>
              <Typography className={classes.paragraphText}>
                E: <a href="mailto:subscriptions@asianinvestor.net">subscriptions@asianinvestor.net</a> | T: <a href="tel:+852 2122 5222">+852 2122 5222</a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid className={classes.leftWrapper}  container direction="row" alignItems="center">
              <Grid item>
                <Link target="_blank" rel="noopener" className={classes.social} href="https://www.linkedin.com/company/asian-investor" >
                  <LinkedIn style={{ fontSize: 40 }} />
                </Link>
              </Grid>
              <Grid item>
                <Link target="_blank" rel="noopener" className={classes.social} href="https://twitter.com/AsianInvestor" >
                  <Twitter style={{ fontSize: 40 }} />
                </Link>
              </Grid>
              <Grid item>
                <Link target="_blank" rel="noopener" className={classes.social} href="https://www.facebook.com/AsianInvestor" >
                  <Facebook style={{ fontSize: 40 }} />
                </Link>
              </Grid>
              <Grid item>
                <Box ml={2}>
                  <Typography className={classes.paragraphText}>Follow us on social media</Typography>
                </Box>
                <Box ml={2}>
                  <Typography className={classes.paragraphText}><strong>@AsianInvestor</strong></Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" >
          <Grid item>
            <Link href="https://haymarket.com" target="_blank" rel="noopener">
              <img src={HaymarketLogo} className={classes.hayLogo} />
            </Link>
          </Grid>
          <Grid item>
            <Typography className={classes.paragraphText} align="center">
              <i>Asset Owner Insights Institutional Report is produced by Haymarket Media Limited, the publisher of AsianInvestor</i>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;