import React, { useContext, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography,  
      FormControl, Select, MenuItem, InputLabel, Box
    } from '@material-ui/core';
import { CompanyContext } from "../CompanyContext";

const AssetClasses = (props) =>{
  const {classes, institutionsInvestmentPortfolioList: rows, institutionsInvestmentPortfolioYearlist: years} = useContext(CompanyContext);
  
  
  const [yearOneData, setOneData] = useState([]);
  const [yearTwoData, setTwoData] = useState([]);
  const [yearOne, setOneValue] = useState(years[0]||'');
  const [yearTwo, setTwoValue] = useState(years[1]||'');


  const handleYearOneChange = (event)=>{
    setOneValue(event.target.value);
    //console.log(event.target.value, rows.filter(o=>(parseInt(o.getYear)===event.target.value)));
    setOneData(rows.filter(o=>(parseInt(o.getYear)===event.target.value)));
  }

  const handleYearTwoChange = (event)=>{
    setTwoValue(event.target.value);
    setTwoData(rows.filter(o=>(parseInt(o.getYear)===event.target.value)));
  }

  

  const renderTableCell = (name, data, i)=>{
    //console.log(name, data, i);
    return(
      
        <Table key={name+i} className={classes.table} size="small" >
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell scope="row">{name}</TableCell>
              <TableCell scope="row" align="right">
                <FormControl className={classes.select}>
                  <Select
                    value={yearOne}
                    onChange={handleYearOneChange}
                  >
                  {
                    years.map((year, i)=>(
                      <MenuItem key={'year1'+year+i} value={year}>{year}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </TableCell>
              {years.length>1 &&
                <TableCell scope="row" align="right">
                  <FormControl className={classes.select}>
                    <Select
                      value={yearTwo}
                      onChange={handleYearTwoChange}
                    >
                    {
                      years.map((year, i)=>(
                        <MenuItem key={'year2'+year+i} value={year}>{year}</MenuItem>
                      ))
                    }
                    </Select>
                    
                  </FormControl>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          {data.map((row, j) => (
            <TableBody key={'table'+j}>
              <TableRow key={row.investmentPortfolioVariablesName+j}>
                <TableCell scope="row">{row.investmentPortfolioVariablesName}</TableCell>
                
                <TableCell scope="row" align="right">
                  {
                    row.variables.map((obj,n) => {
                      if((parseInt(obj.getYear)===yearOne)){
                        return (
                          <span key={`year1${obj.getYear}${n}`}>{(obj.value!==null && obj.typeName==='%')?obj.value+' %':(obj.value!==null)?obj.value:''}</span>
                        )
                      }else{
                        return '';
                      }
                    })
                  }
                </TableCell>  
                {years.length>1 &&
                  <TableCell scope="row" align="right">
                    {
                      row.variables.map((obj,n) => {
                        if((parseInt(obj.getYear)===yearTwo)){
                          return (
                            <span key={`year2${obj.getYear}${n}`}>{(obj.value!==null && obj.typeName==='%')?obj.value+' %':(obj.value!==null)?obj.value:''}</span>
                          )
                        }else{
                          return '';
                        }
                        
                      })
                    }
                  </TableCell>  
                }
              </TableRow>
            </TableBody>
          ))}
        </Table>
    )
  }

  if (years.length == 0) return null;
  

  return (
    <Grid id={props.id} container spacing={2} className={classes.section}>
      <Grid item xs={12} className={classes.subTitle}>
        <Typography variant="subtitle1" gutterBottom>
          {'Data point of each asset classes'}
        </Typography>
        {/*<Typography variant="subtitle2" gutterBottom>
          {'Last update at:'} {'Dec 11, 2020'}
        </Typography>*/}
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
        {
          rows.map((item, i)=>(
            renderTableCell(item.investmentPortfolioName, item.variables, i)
          ))
        }
        </TableContainer>
      </Grid>
      { /*(years.length>1) && 
        <Grid item xs={12} className={classes.compareTables}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Box mb={2}>
                <FormControl className={classes.select}>
                  <InputLabel id="demo-simple-select-label">Year 1</InputLabel>
                  <Select
                    value={yearOne}
                    onChange={handleYearOneChange}
                  >
                  {
                    years.map((year, i)=>(
                      <MenuItem key={year+i} value={year}>{year}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </Box>
              <TableContainer>
                {(yearOneData && yearOneData.length>0)?
                  yearOneData.map((item, i)=>(
                    renderTable(item, i)
                  )):
                  <Typography gutterBottom>
                    {'Please select the year to compare'}
                  </Typography>
                }
              </TableContainer>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box  mb={2} >
                <FormControl className={classes.select}>
                  <InputLabel id="demo-simple-select-label">Year 2</InputLabel>
                  <Select
                    value={yearTwo}
                    onChange={handleYearTwoChange}
                  >
                  {
                    years.map((year, i)=>(
                      <MenuItem key={year+i} value={year}>{year}</MenuItem>
                    ))
                  }
                  </Select>
                </FormControl>
              </Box>
              <TableContainer>
                {(yearTwoData && yearTwoData.length>0)?
                  yearTwoData.map((item, i)=>(
                    renderTable(item, i)
                  )):
                  <Typography gutterBottom>
                    {'Please select the year to compare'}
                  </Typography>
                }
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      */}
    </Grid>
  );
}

export default AssetClasses;