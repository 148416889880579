import React, {useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Select, FormControl, InputLabel, MenuItem} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width:'100%',
    padding: theme.spacing(1),
    '& .MuiInput-underline:after':{
      borderBottomColor:theme.palette.secondary.main
    }
  },
  label:{
    color: '#000',
    left: 'auto',
    fontWeight:'bold',
    fontSize:theme.typography.pxToRem(14),
    '&.Mui-focused':{color:theme.palette.secondary.main},
    '&.MuiInputLabel-shrink':{
      fontSize:theme.typography.pxToRem(16),
      color:theme.palette.secondary.dark
    }
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectText:{
    fontWeight:'bold',
    fontSize:theme.typography.pxToRem(14)
  },
  filterText:{
    fontSize:theme.typography.pxToRem(13)
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(item, selected, theme) {
  return {
    fontWeight:
      (item===selected && selected!=='')
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Filter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {items, filterName, selectedItem, multiple } = props; 

  const [selected, setSelected] = useState(selectedItem);

  const handleChange = (event) => {
    //console.log(event);
/*    if(multiple){
      let _selected = [...selected];
      let index = _selected.indexOf(event.target.value);
      if(index>-1){
        _selected.splice(index,1);
      }else{
        _selected.push(event.target.value);
      }
      setSelected(_selected);
      //props.onSelect({name:filterName, value:_selected});
    }else{*/
      if(event.target && event.target.value!==selected){
        setSelected(event.target.value);
        props.onSelect({name:filterName, value:event.target.value});
      }
    // }
  };

  useEffect(()=>{
    setSelected(selectedItem)
  },[]);

  return (
    <div key={filterName}>
      {(items.length>0) &&
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>{filterName}</InputLabel>
          {multiple?
            <Select
              multiple
              value={selected}
              onChange={handleChange}
              MenuProps={MenuProps}
              className={classes.selectText}
            > 
              {items.map((item) => (
                <MenuItem className={classes.filterText} key={item.key} value={item.key} style={getStyles(item.key, selected, theme)}>
                  {item.key}
                </MenuItem>
              ))}
            </Select>
            :
            <Select
              value={selected}
              onChange={handleChange}
              MenuProps={MenuProps}
              className={classes.selectText}
            > 
              {items.map((item) => (
                <MenuItem className={classes.filterText} key={item.key} value={item.key} style={getStyles(item.key, selected, theme)}>
                  {item.key}
                </MenuItem>
              ))}
            </Select>
          }
          
        </FormControl>
      }
    </div>
  );
}
